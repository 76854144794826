<template>
  <div class="margin-bottom-50">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card border shadow">
          <div class="card-body">
            <div class="flex justify-content-between">
              <div style="display: flex;justify-content: space-around">
                <img :src="logo" style="width: 60px;height: 60px;border-radius: 50%;" v-if="logo" alt="business name"/>
                <img src="../../../assets/images/account.svg" style="width: 60px;height: 60px;border-radius: 50%;"
                     v-else/> <span
                  class="ml-3 pt-3 text-[15px] hide-sm">Business logo</span>
              </div>
              <div class="">
                <label class="image-label">
                  <span class="text-[12px]">Upload logo <i class="fa fa-upload ml-2 icon-color"></i></span>
                  <input id="inputTag" type="file" v-on:change="updateLogo($event)"
                         accept="image/png, image/jpg, image/gif, image/jpeg"/>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card border shadow">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-[2px]  !text-[14px]">Business name:</label>
                  <el-input placeholder="Business name" class="w-100" v-model="payload.business_name"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Business email:</label>
                  <el-input placeholder="Business email" class="w-100" v-model="payload.business_email"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Business number | CAC
                    number:</label>
                  <el-input placeholder="Business number" class="w-100" v-model="payload.business_number"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Business
                    website:</label>
                  <el-input placeholder="Business website" class="w-100" v-model="payload.business_website"></el-input>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card  border shadow">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">About Business</h2>
                  <p></p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-2  !text-[14px]" style="margin-bottom: 2px !important;">Industry:</label>
                  <el-select v-model="payload.industry" filterable placeholder="Select industry"
                             style="width: 100%">
                    <el-option
                        v-for="(item,key) in industries"
                        :key="key"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Business
                    description:</label>
                  <textarea class="min-h-[100px] border w-full rounded-[3px] px-[10px] py-[5px] !text-[14px]" cols="5"
                            rows="5" v-model="payload.business_description"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card  border shadow">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">Location information</h2>
                  <p></p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Country:</label>
                  <el-select v-model="payload.country" placeholder="Select country" v-on:change="getState"
                             style="width: 100%">
                    <el-option
                        v-for="(item,key) in GET_COUNTRY.data"
                        :key="key"
                        :label="item.name"
                        :value="item.code">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">State:</label>
                  <el-select v-model="payload.state" placeholder="Select state" v-on:change="getState"
                             style="width: 100%">
                    <el-option
                        v-for="(item,key) in GET_STATE"
                        :key="key"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" native-type="submit" :disabled="loading" v-on:click="submit"
                   v-if="loadingInformation === false"
                   style="margin-bottom: 100px;float: left">
          Update <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>
    </div>
  </div>

</template>

<script>
import shimmer from "../../reusable/shimmer";
import {mapGetters} from "vuex";

export default {
  name: "BusinessDetails",
  components: {shimmer},
  data: function () {
    return {
      loadingInformation: true,
      loading: false,
      payload: {
        "business_name": "",
        "business_email": "",
        "business_website": "",
        "business_number": "",
        "industry": "",
        "business_description": "",
        "country": "",
        "state": "",
      },
      industries: ['Accounting', 'Airlines/Aviation', 'Alternative dispute resolution', 'Alternative dispute resolution',
        'Alternative medics', 'Animation', 'Apparel & Fashion', 'Architecture & planning', 'Arts and craft', 'Automotive',
        'Aviation & aerospace', 'Business supplies & equipment', 'Biotechnology', 'Broadcast media',
        'Building materials', 'Computer & network security', 'Computer software', 'Consumer services',
        'Commercial real estate', 'Dairy', 'Design', 'Graphic design', 'Civic & social organization',
        'Defense & space', 'Ecommerce', 'Event services', 'Environment services', 'Entertainment',
        'Education management', 'E-learning', 'Fine Art', 'Financial services', 'Fishery', 'Farming',
        'Facilities services', 'Food & beverages', 'Furniture', 'Food production', 'Fund-raising',
        'Government administration', 'Health, wellness & fitness', 'Higher education', 'Import & export',
        'Industrial automation', 'International affairs', 'Individual and family services',
        'International trade & development', 'Logistics & supply chain', 'Legal services', 'Marketplace',
        'Marketing & Advertising', 'Medical devices', 'Motion pictures & film', 'Mechanical or industrial engineering',
        'Museums & institutions', 'NGO', 'Oil and energy', 'Packaging & containers', 'Performing arts',
        'Primary and secondary education', 'Package/Freight delivery', 'Paper & Forest product', 'Program development',
        'Public relations & communications', 'Public safety', 'Recreational facilities & services', 'Real estate',
        'Restaurant', 'Renewables and environment', 'Security & investigations', 'Staffing & recruiting',
        'Semiconductors', 'Sporting goods', 'Ship building', 'Staffing and recruiting',
        'Supermarkets', 'Translation & localization', 'Venture capital & private equity', 'Wine & spirits', 'Writing and editing'],
      logo: null
    }
  },
  methods: {
    getState() {
      this.$store.dispatch('general/getState', {'code': this.payload.country})
    },
    getCountry: function () {
      this.$store.dispatch('general/getCountry')
    },
    getBusinessInfo: function () {
      this.loadingInformation = true
      this.$store.dispatch('user/GET_MERCHANT', {'username': this.GET_USER.username}).then(() => {
        this.loadingInformation = false
        this.logo = this.GET_MERCHANT_INFO.business_logo
        this.payload = {
          "business_name": this.GET_MERCHANT_INFO.business_name,
          "business_email": this.GET_MERCHANT_INFO.business_email,
          "business_website": this.GET_MERCHANT_INFO.business_website,
          "business_number": this.GET_MERCHANT_INFO.business_number,
          "industry": this.GET_MERCHANT_INFO.industry,
          "business_description": this.GET_MERCHANT_INFO.business_description,
          "country": this.GET_MERCHANT_INFO.country,
          "state": this.GET_MERCHANT_INFO.state,
        }

      }).catch(() => {
        this.loadingInformation = false
      })
    },
    submit: function () {
      this.loading = true
      this.$store.dispatch('user/UPDATE_MERCHANT', {'data': this.payload, 'username': this.GET_USER.username})
          .then(() => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: `Business information updated successfully`
            })

          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
    updateLogo: function () {
      let inputImage = document.querySelector("input[type=file]").files[0];
      let formdata = new FormData()
      formdata.append('business_logo', inputImage)
      this.$store.dispatch('user/UPDATE_MERCHANT', {
        'username': this.GET_USER.username,
        'data': formdata
      }).then(() => {
        this.logo = this.GET_MERCHANT_INFO.business_logo
        this.$toast.success({
          title: 'success',
          message: `Business logo updated successfully`
        })
      }).catch(() => {
        this.$toast.warning({
          title: 'warning',
          message: 'Business logo could not be uploaded ,Kindly try again'
        })
      })
    }
  },
  mounted() {
    this.getBusinessInfo()
    this.getCountry()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_COUNTRY: 'general/GET_COUNTRY',
      GET_STATE: 'general/GET_STATE',
      GET_MERCHANT_INFO: 'user/GET_MERCHANT_INFO'
    })
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.image-label {
  cursor: pointer !important;
  position: relative;
  top: 9px;
  background: #fff;
  box-shadow: 0px 3px 6px rgb(55 55 89 / 3%) !important;
  padding: 10px;
  border: 2px solid #f9f9fb;
}

.icon-color {
  color: #edebeb !important;
}

.hide-sm {
  display: block;
}

@media only screen and (max-width: 640px) {
  .hide-sm {
    display: none
  }
}
</style>