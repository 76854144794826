<template>
  <div class="min-h-[650px] py-6 px-[25px] bg-mainBG">
    <div class="w-full py-[10px] px-[10px]">
      <h2 class="text-left text-black font-semibold pb-[10px]">Hi {{
          GET_USER.last_name
        }}, Welcome to Card-fare.</h2>
      <p class="text-left text-[14px]">Let’s get you started with setting up your account.</p>
    </div>
    <div class="border mt-[40px] rounded-xl shadow-xs bg-white min-h-[400px]">
      <div class="block lg:flex gap-2">
        <div class="w-full lg:w-[25%] bg-[#fafdff] h-full lg:h-[400px] py-[10px]">
          <div class="w-full py-[10px]">
            <h2 class="text-[17px] font-semibold px-[30px]">Setup Guide</h2>
          </div>
          <div class="w-full">
            <ul class="block">
              <li class="w-full cursor-pointer text-[14px]" v-for="(item,key) in tabList"
                  :key="item.key" :class="{'border-r !border-r-[3px] !border-r-[#8862df]': item.open === true}">
                <get-started-list :tab="item" :index="key" @change="onChangeTab"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full lg:w-[75%]">
          <kyb v-if="isKyb" @goto="gotTo"/>
          <security_key v-if="isSecurityKey" @goto="gotTo"/>
          <bank_information v-if="isBankInfo" @goto="gotTo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getStartedList from "../../components/getStartedList";
import kyb from "../../components/started/kyb";
import security_key from "../../components/started/security_key";
import bank_information from "../../components/started/bank_information";
import {mapGetters} from "vuex";

export default {
  name: "get-started",
  components: {
    getStartedList,
    kyb,
    security_key,
    bank_information
  },
  data: function () {
    return {
      tabList: [
        {
          name: 'Complete KYB',
          key: 'business_information',
          open: true,
          verified: false
        },
        {
          name: 'Security key',
          key: 'security_key',
          open: false,
          verified: false
        },
        {
          name: 'Bank information',
          key: 'bank_info',
          open: false,
          verified: false
        },
      ]
    }
  },
  methods: {
    onChangeTab(tab) {
      this.tabList.map(item => {
        item.open = item.key === tab;
      })
    },
    gotTo: function (tab) {
      this.$router.push({
        'name': 'settings',
        query: {
          'tab': tab
        }
      })
    },
    setUpTab() {
      this.tabList.map(item => {
        if (item.key === 'bank_info') {
          if (this.GET_MERCHANT_INFO.is_bank_verified === true) {
            item.verified = true
          }
        } else if (item.key === 'security_key') {
          if (this.GET_MERCHANT_INFO.has_security_key === true) {
            item.verified = true
          }
        } else if (item.key === 'business_information') {
          if (this.GET_MERCHANT_INFO.is_business_verified === true) {
            item.verified = true
          }
        }
      })
    }
  },
  mounted() {
    this.setUpTab()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_MERCHANT_INFO: 'user/GET_MERCHANT_INFO'
    }),
    isKyb() {
      return this.tabList.filter(item => item.key === 'business_information' && item.open === true).length > 0
    },
    isSecurityKey() {
      return this.tabList.filter(item => item.key === 'security_key' && item.open === true).length > 0
    },
    isBankInfo() {
      return this.tabList.filter(item => item.key === 'bank_info' && item.open === true).length > 0
    },
  },

}
</script>

<style scoped>

</style>