<template>
  <div id="main-wrapper">
    <CustomerSidebar class="w-[30%]"/>
    <div class="contentbody h-[100vh] lg:ml-[240px] overflow-y-scroll bg-gray-50">
      <CustomerHeader/>
      <router-view/>
      <Footer/>
    </div>

    <Modal v-if="openPasswordModal" :width="600" title="UPDATE PASSWORD" @handleClose="openPasswordModal = false">
      <div class="container" slot="body">
        <form v-on:submit.prevent="submitPasswordForm">
          <div class="row">
            <div class="col-md-12 mt-3" style="position:relative;">
              <label class="mr-sm-2 label">Old Password:</label>
              <el-input placeholder="Old password" type="password" class="w-100" show-password
                        v-model="passwordForm.old_password" required></el-input>
            </div>
            <div class="col-md-12 mt-3" style="position:relative;">
              <label class="mr-sm-2 label">New Password:</label>
              <el-input placeholder="New password" type="password" required class="w-100" show-password
                        v-model="passwordForm.new_password"></el-input>
            </div>
            <div class="col-md-12 mt-3" style="position:relative;">
              <label class="mr-sm-2 label">Confirm Password:</label>
              <el-input placeholder="Confirm password" required type="password" class="w-100" show-password
                        v-model="confirm_password"></el-input>
            </div>
            <div class="col-md-12">
              <el-button type="primary" native-type="submit" class="mt-4"
                         :disabled="loadingWithdrawForm">Update password <i
                  class="el-icon-loading ml-2" v-if="loadingWithdrawForm"></i>
              </el-button>
            </div>
          </div>
        </form>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
    <el-drawer
        title="I am the title"
        :modal='false'
        :size="logDrawerSize"
        :visible.sync="openWithdrawForm"
        :before-close="handleClose"
        :with-header="false">
      <div class="w-full h-full px-[20px]">
        <create-withdraw v-if="openWithdrawForm" @close="handleClose"/>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import CustomerHeader from "../../layout/header/CustomerHeader";
import CustomerSidebar from "../../layout/sidebar/CustomerSidebar";
import createWithdraw from "../../components/withdraw/createWithdraw";
import Footer from "../../layout/Footer";
import isScreen from "../../helpers/screenHelper";
import {mapGetters} from "vuex"
import Modal from "../../components/Modal";

export default {
  name: "MainComponent",
  components: {CustomerSidebar, CustomerHeader, Footer, Modal, createWithdraw},
  data: function () {
    return {
      openWithdrawForm: false,
      innerDrawer: true,
      loadingWithdrawForm: false,
      logDrawerSize: '40%',
      openPasswordModal: false,
      withdrawForm: {
        amount: 0,
      },
      passwordForm: {
        old_password: '',
        new_password: '',
      },
      confirm_password: ''
    }
  },
  methods: {
    handleClose() {
      this.openWithdrawForm = false
    },
    errorFormat(error, nameMap) {
      try {
        if (Array.isArray(error.response.data.error)) {
          this.$toast.warn({
            title: 'warning',
            message: error.response.data.error.join(',')
          })
        } else if (typeof error.response.data.error === 'object') {
          for (const [key, value] of Object.entries(error.response.data.error)) {
            if (Array.isArray(value)) {
              this.$toast.warn({
                title: 'warning',
                message: nameMap[key] + ': ' + value.join(',')
              })
            } else {

              this.$toast.warn({
                title: 'warning',
                message: value
              })
            }
            return false
          }
        } else {
          this.$toast.warn({
            title: 'warning',
            message: error.response.data.message
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    resize() {
      if (isScreen('xs') || isScreen('sm') || isScreen('md')) {
        this.$store.commit('general/SET_SIDE_BAR', {'status': false})
        this.logDrawerSize = '100%'
      } else {
        this.$store.commit('general/SET_SIDE_BAR', {'status': true})
        this.logDrawerSize = '45%'
      }
    },
    submitWithdrawForm() {
      this.loadingWithdrawForm = true
      this.$store.dispatch('withdraw/createWithdrawal', this.withdrawForm).then(() => {
        this.loadingWithdrawForm = false
        this.$toast.success({
          title: 'success',
          message: 'Withdrawal request created successfully'
        })
        this.openPasswordModal = false
      }).catch(err => {
        this.openPasswordModal = false
        this.$toast.warn({
          'title': 'warning',
          'message': err.response.data.message
        })
      })
    },
    submitPasswordForm() {
      this.loadingWithdrawForm = true
      if (this.passwordForm.new_password !== this.confirm_password) {
        this.loadingWithdrawForm = false
        this.$toast.warn({
          title: 'warning',
          message: 'Password does not match'
        })
        return false
      }
      this.$store.dispatch('user/UPDATE_PASSWORD', {data: this.passwordForm}).then(() => {
        this.loadingWithdrawForm = false
        this.$toast.success({
          title: 'success',
          message: 'Password updated successfully'
        })
        this.openPasswordModal = false
        this.$store.dispatch('user/LOGOUT').then(() => {
          const routerName = this.$router.resolve({name: 'login'})
          setTimeout(() => {
            window.open(routerName.href, '_parent')
          }, 200)
        })
      }).catch(err => {
        this.openPasswordModal = false
        this.$toast.warn({
          'title': 'warning',
          'message': err.response.data.message
        })
      })
    },
    globalReload() {
      this.$store.dispatch('user/GET_ACCOUNT_WALLET')
      this.$store.dispatch('transactions/getTransaction', {data: ``})
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    })
  },
  mounted() {
    this.resize()
    let self = this
    window.Bus.$on('open-withdraw-form', () => {
      this.openWithdrawForm = true
    })
    window.Bus.$on('logout', () => {
      self.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = self.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    })
    window.Bus.$on('open-password-modal', () => {
      this.openPasswordModal = true
    })
    window.Bus.$on('reload-global', () => {
      this.globalReload()
    })
    window.addEventListener("resize", () => {
      self.resize()
    });
  }
}
</script>

<style scoped>


.el-button:hover, .el-button:focus {
  border: none !important;
}

.label-color {
  position: relative;
  top: 10px;
}

.table th, .table td {
  padding: 10px;
  font-weight: 600;
}

.table tr {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
}

.table tr td, .table tr th {
  background: none;
}

.el-drawer__wrapper > #el-drawer__title > span {
  text-align: left !important;
  font-weight: 700;
}

.no-border {
  border-radius: 0px !important;
}

.card__name {
  float: left;
  font-size: 13px;
  margin-top: 5px;
}

.note-text {
  text-align: start;
  padding-left: 14px;
}

.naira-icon {
  position: absolute;
  left: 29px;
  top: 34px;
  font-weight: 700;
}

.el-input__inner {
  padding-left: 34px;
}
</style>