<template>
  <div class="min-h-[650px] py-6 px-[25px] bg-mainBG">
    <div class="flex flex-row w-full">
      <dashboard-banner/>
    </div>
    <div class="row">
      <div class="col-md-12">

        <div class="row">

          <div class="col-xl-7">
            <graph-component/>
          </div>
          <div class="col-md-5">
            <div class="mt-1">
              <div class="row">
                <div class="col-md-12">
                  <transaction-table/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DashboardBanner from "./components/DashboardBanner";
import graphComponent from "./components/graphComponent";
import TransactionTable from "./components/TransactionTable";

export default {
  name: "dashboard",
  components: {DashboardBanner, graphComponent, TransactionTable},
  data: function () {
    return {
      serverLineSeries: [
        {
          name: 'Website Blog',
          type: 'area',
          data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
        },
        {
          name: 'Social Media',
          type: 'area',
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        }
      ],
      options: {
        chart: {
          id: 'vuechart-exaple',
          height: 200,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          labels: {
            style: {
              colors: '#7987a1',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#7987a1',
            },
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            },
            columnWidth: '30%',
            endingShape: 'rounded',
          }
        },
        colors: ['#0589E8'],
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
        },
        stroke: {
          show: true,
          width: 2,
          endingShape: 'rounded',
          colors: ['transparent'],
        },
        responsive: [{
          breakpoint: 576,
          options: {
            stroke: {
              show: true,
              width: 1,
              endingShape: 'rounded',
              colors: ['transparent'],
            },
          },
        }],
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          floating: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        tooltip: {
          theme: 'dark'
        }
      },
      dateObject: {
        range: '',
        date: '',
      },
      graphData: {
        options: {},
        series: [
          {
            name: 'Website Blog',
            type: 'area',
            data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
          },
        ]
      },
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    })
  },
  methods: {
    getSetupStatus() {

    }
  },
  mounted() {
    // this.getKpi()
    // window.Bus.$on('reload-customer-kpi', () => {
    //   this.getKpi()
    // })
  }
}
</script>

<style scoped>
.widget-info {
  position: relative;
  top: 28px;
  font-weight: 600;
  font-size: 27px;
}

.bg-blue {
  background: #2ba4d1 !important;
  color: #ffffff !important;
}

.amount__ {
  text-align: left;
  padding-top: 20px;
  font-size: 27px;
  padding-bottom: 20px;
  font-weight: 600;
}

.custom-card__ {
  border-radius: 6px;
  background: #4f8ca3;
}

.no-bg {
  background: transparent !important;
}

.__font_size_27 {
  font-size: 27px !important;
}

.__mt10 {
  margin-bottom: 10px !important;
}

#app {
  margin-top: 10px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.text-med {
  font-size: 16px;
  line-height: 24px;
}

.text-black-500 {
  --tw-text-opacity: 1;
  color: rgba(43, 43, 43, var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}

.text-h1 {
  font-size: 28px;
  line-height: 12px;
}

.to-Uppercase {
  text-transform: uppercase;
}
</style>