<template>
  <div class="w-full">
    <div class="w-full py-[20px]">
      <div class="text-center font-light text-xs">
        <span>Copyright © <span>{{ current }} Hardweb solutions</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer',
  data() {
    return {
      current: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>