<template>
  <div style="background-color:#f9f9fb !important;">
    <div class="v-application v-application--is-ltr theme--light">
      <div class="v-application--wrap">
        <div class="v-main login">
          <div class="v-main__wrap">
            <div class="container-fluid fill-height">
              <div class="row pl-0 fill-height">
                <div class="login-content-wrapper d-none d-md-flex col-md-7 col-12">
                  <div class="login-content">
                    <svg aria-hidden="true" style="height: 170px; margin: auto"
                         class="svg-inline--fa fa-key fa-w-16 v-icon notranslate login-page-icon theme--light"
                         focusable="false" data-prefix="fas" data-icon="key" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                            d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path>
                    </svg>
                  </div>
                </div>
                <div class="form-wrapper col-md-5 col-12 auth-inner">
                  <div class="login-wrapper">
                    <img src="./../../assets/images/cardfare_bg_2.png" data-selenium="logo"
                         class="logo" style="height: 72px"
                         alt="logo">
                    <h1 class="text-left">Reset your password</h1>
                    <p class="preamble text-left mb-4">
                      Enter your account details. An email with instructions will be sent to the email address
                      associated with the account.
                    </p>
                    <div class="alert alert-success" v-if="status === true">Reset password link sent to your mail</div>
                    <div class="alert alert-danger" v-if="error === true">Something went wrong while sending your
                      Password reset link
                    </div>
                    <form v-on:submit.prevent="submit" class="signin_validate" style="margin-bottom: 24px"
                    >
                      <div class="form-group margin-bottom-10">
                        <label class="label" style="margin-bottom: 2px
;">Email</label>
                        <el-input placeholder="Email" class="w-100" v-model="form.username"></el-input>
                      </div>
                      <div class="text-center mt-3">
                        <button type="submit"
                                class="btn btn-block font-weight-500 text-white primary__color"
                                style="border-radius: 5px"
                                :disabled="loading">SET NEW PASSWORD <i
                            class="el-icon-loading" v-if="loading"></i></button>
                      </div>
                    </form>
                    <div class="form-group">
                      <p>
                        I know my password,
                        <router-link :to="{name:'login'}" class="mb-2"
                                     style=" margin-bottom: 12px !important; font-size: .875rem;  margin-top: 10px !important;text-align: center !important;">
                          Sign in
                        </router-link>
                      </p>
                    </div>
                    <div class="site-info-wrapper"><span>Copyright ©</span>
                      {{ year }}
                      <a href="https://www.cardfare.ng"><span id="__name">Cardfare</span></a></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ForgetPassword",
  data: function () {
    return {
      form: {
        username: ''
      },
      remember_me: false,
      loading: false,
      status: false,
      error: false,
      year: new Date().getFullYear()
    }
  },
  mounted() {

  },
  methods: {
    submit: function () {
      this.loading = false
      this.error = false
      this.success = false
      for (const [key, value] of Object.entries(this.form)) {
        if (value === '' || value === null) {
          this.loading = false
          return this.$toast.warn({
            title: 'warning',
            message: `${key.replace('_', ' ')} is required`
          })
        }
      }
      this.loading = true
      this.$store.dispatch('auth/forget_password', this.form).then(() => {
        this.loading = false
        this.status = true
        this.reset()
      }).catch(() => {
        this.loading = false
        this.error = true
        this.reset()
      })
    },
    reset: function () {
      let self = this
      setTimeout(function () {
        self.status = false
        self.error = false
      }, 3000)
    }
  }
}
</script>

<style scoped>
.font-weight-800 {
  font-weight: 800;
}

.no-border-radius {
  border-radius: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-7 {
  margin-top: 7px !important;
}

.theme--light.v-application {
  background: #fff;
  color: rgba(0, 0, 0, .87);
}

.v-application {
  display: flex;
}

.v-application--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.login-page .v-content, .login-page .v-main {
  padding: 0 !important;
}

main.login, .login {
  background-color: #ffffff !important;
}

.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4, 0, .2, 1);
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}

@media screen and (max-height: 640px) {
  .login-page .v-content .container, .login-page .v-main .container {
    margin-bottom: 55px;
  }

  .login-wrapper {
    margin: 0 auto;
  }

  .auth-inner:before {
    display: none
  }

  .logo {
    height: 59px !important;
    margin: 0 auto 81px 0 !important;
  }

  .register-text {
    text-align: center !important;
    float: none !important;
  }

  .reset-password {
    float: none !important;
    text-align: center;
  }

  .site-info-wrapper {
    color: #777;
    font-size: 12px;
    align-self: center !important;
    margin-top: 0px !important;
    position: relative;
    top: 59px !important;
    text-align: center;
  }

  .form-wrapper h1 {
    color: #282947;
    text-transform: none;
    margin-bottom: 12px !important;
    font-size: 23px !important;
  }

  .preamble {
    font-size: 12px !important;
  }

  .mt-sm-8 {
    margin-top: 8px;
  }
}


.login-page .v-content .container, .login-page .v-main .container {
  margin: 0 !important;
  padding: 0 !important;
}

.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100% + 24px);
}

.login-page .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-application .pl-0 {
  padding-left: 0 !important;
}

.login-wrapper {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.logo {
  margin: 0 auto 97px 0;
  display: block;
  padding-top: 30px;
}

.form-wrapper h1 {
  color: #282947;
  text-transform: none;
  margin-bottom: 25px;
  font-size: 27px;
  font-weight: 600;
  line-height: 1.4;

}

#app {
  margin-top: 0px !important;
}

.preamble {
  font-size: 15px;
  color: #666;
  margin-bottom: 0px;
  font-weight: 600;
}


.auth-inner:before {
  width: 244px;
  height: 243px;
  content: ' ';
  position: absolute;
  top: 457px;
  left: -46px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC)
}

.login-content-wrapper {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ebe9fc;
  height: 759px !important;
}

.login-content-wrapper .login-content {
  position: relative;
  padding: 15px;
  width: 100%;
}

.site-info-wrapper {
  color: #777;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 20px;
  position: relative;
  top: 185px;
  text-align: right;
}

#__name {
  color: #8862df;
  font-weight: 600;
}

.home-illustration {
  max-width: 780px;
  min-width: 300px;
}

@media only screen and (max-width: 600px) {
  .site-info-wrapper {
    top: 62px !important;
  }
}
</style>