<template>
  <div class="p-4 bg-mainBG">
    <div
        class="text-sm font-medium text-center">
      <TabComponents :tabs="tabs" @change="switchTab" :css="`flex gap-2 `"/>
    </div>
    <personal-details v-if="tab === 'personal'" class="mt-2"/>
    <business-details v-if="tab === 'business'" class="mt-2"/>
    <bank-details v-if="tab === 'bank'" class="mt-2"/>
    <security v-if="tab === 'security'" class="mt-2"/>
  </div>
</template>
<script>
import TabComponents from "../../components/Tabs/index";
import PersonalDetails from "./settings/PersonalDetails";
import BusinessDetails from "./settings/BusinessDetails";
import BankDetails from "./settings/BankDetails";
import security from "./settings/security";

export default {
  name: "setting",
  components: {PersonalDetails, BusinessDetails, BankDetails, security, TabComponents},
  data: function () {
    return {
      tab: "personal",
      tabDict: {
        'business': 1,
        'bank_information': 3,
        'security_pin': 4
      },
      tabs: [
        {
          name: 'Personal Details',
          slug: 'personal',
          active: true
        },
        {
          name: 'Business Details',
          slug: 'business',
          active: false
        },
        {
          name: 'Bank Details',
          slug: 'bank',
          active: false
        },
        {

          name: 'Security',
          slug: 'security',
          active: false
        }
      ]
    }
  },
  methods: {
    switchTab: function (tab) {
      const {slug} = tab
      this.tabs.forEach((item) => {
        item.active = item.slug === slug;
      })
      this.tab = slug
    },
  },
  mounted() {
    const {tab} = this.$route.query
    if (tab) {
      if (Object.keys(this.tabDict).includes(tab)) {
        this.tab = parseInt(this.tabDict[tab])
      }

    }
  }
}
</script>
