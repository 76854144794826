<template>
  <div>
    <div v-if="loading === 'loading'" class="min-h-80 flex justify-center items-center">
      <i
          class="el-icon-loading text-5xl"></i>
    </div>
    <div v-else-if="loading === 'success'" class="flex flex-col gap-3">
      <p class="text-base text-wrap">
        Kindly confirm the payout request for wallet amount <span
          class="font-semibold">NGN{{ getAmount(walletInfo.amount) }}</span>, enter your security pin to complete the
        payout
      </p>

      <form v-on:submit.prevent="submitForm" class="flex flex-col gap-4">
        <div class="mt-3">
          <label class="mr-sm-2 label">Security Pin</label>
          <el-input placeholder="" type="text" class="w-full"
                    v-model="payload.pin"
                    required></el-input>
        </div>
        <div class="flex flex-col gap-3 items-center lg:flex-row lg:justify-end justify-center">
          <el-button native-type="button" class="w-full lg:w-auto !hover:border !hover:bg-white !focus:bg-white"
                     :disabled="onSubmitLoading" v-on:click="onCancel">Cancel

          </el-button>
          <el-button type="primary" native-type="submit" class="w-full lg:w-auto !ml-0"
                     :disabled="onSubmitLoading">Submit<i
              class="el-icon-loading" v-if="onSubmitLoading"></i>

          </el-button>
        </div>
      </form>
    </div>
    <div v-else class="min-h-80 flex items-center">
      <div class="bg-red-500 p-2 rounded w-full">
        <p class="text-sm text-wrap">Ops, Wallet information could not be retrieve currently, Try again!.</p>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {errorFormat, formatAmount} from "../../../../utils";

export default {
  name: "Withdraw",
  props: {},
  data: function () {
    return {
      loading: "loading",
      onSubmitLoading: false,
      walletInfo: {
        merchant: '',
        amount: 0
      },
      payload: {
        amount: 0,
        pin: ""
      }
    }
  },
  methods: {
    onCancel: function () {
      this.$emit('close')
    },
    getWallet: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMerchantWallet', {merchant: merchant}).then(resp => {
        this.walletInfo = resp.data
        this.loading = "success"
      }).catch(() => {
        this.loading = "error"
      })
    },
    getAmount: function (amount) {
      return formatAmount(amount)
    },
    submitForm: function () {
      if (!this.payload.pin) {
        return
      }
      this.onSubmitLoading = true
      this.$store.dispatch("meter_transaction/createPayoutRequest", {
        data: {
          ...this.payload,
          amount: this.walletInfo.amount
        },
        merchant: this.GET_USER.merchant.code
      }).then(() => {
        this.onSubmitLoading = false
        this.$toast.success({
          'title': 'success',
          'message': "Meter updated successfully"
        })
        this.$emit('onSuccess')

      }).catch((err) => {
        const message = errorFormat({error: err})
        this.onSubmitLoading = false
        this.$toast.warn({
          'title': 'warning',
          'message': message
        })
      })
    }
  },
  mounted() {
    this.getWallet()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METERS: 'meter/GET_METERS'
    })
  }
}
</script>

<style scoped>

</style>