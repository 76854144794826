<template>
  <div class="margin-bottom-50">
    <div class="row">
      <div class="col-md-8 mx-auto"  style="margin-top: 54px;">
        <div class="card card-custom">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">Device information</h2>
                  <p class="text-left" style="font-size: 13px;margin-top: 4px">Here's what we know about you. Please do
                    update it if there have been any recent changes.</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1" style="margin-bottom: 2px !important;">Serial number:</label>
                  <el-input placeholder="Serial number" v-model="GET_SINGLE_DEVICE.setting.serial_number" :disabled="true"
                            class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1" style="margin-bottom: 2px !important;">Display name:</label>
                  <el-input placeholder="Display name" v-model="payload.display_name" class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1" style="margin-bottom: 2px !important;">Default price:</label>
                  <el-input placeholder="Default price" v-model="payload.transport_fare_price" type="number"
                            class="w-100"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-button type="primary" native-type="submit" v-on:click="submit" :disabled="loading"
                   v-if="loadingInformation === false"
                   style="margin-bottom: 100px;float: left">
          Update <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import shimmer from "../../../reusable/shimmer";

export default {
  name: "DeviceDetail",
  components: {shimmer},
  data: function () {
    return {
      loading: false,
      loadingInformation: true,
      payload: {
        display_name: '',
        transport_fare_price: '',
      }
    }
  },
  methods: {
    submit: function () {
      this.loading = true
      let form = this.payload
      for (const [key, value] of Object.entries(form)) {
        if (value === '' || value === null) {
          this.loading = false
          return this.$toast.warn({
            title: 'warning',
            message: `${key.replace('_', ' ')} is required`
          })
        }
      }
      this.$store.dispatch('device/updateDevice', {'data': form, 'id': this.GET_SINGLE_DEVICE.id})
          .then(() => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: `Device updated successfully`
            })

          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
    getSingleDevice() {
      this.loadingInformation = true
      this.$store.dispatch('device/getDevice', {'id': this.$route.params.id}).then(() => {
        this.loadingInformation = false
        this.payload = {
          display_name: this.GET_SINGLE_DEVICE.display_name,
          transport_fare_price: this.GET_SINGLE_DEVICE.transport_fare_price,
        }
      }).catch(() => {
        this.loadingInformation = false
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_SINGLE_DEVICE: 'device/GET_DEVICE'
    })
  },
  mounted() {
    this.getSingleDevice()

  }
}
</script>

<style scoped>

</style>