<template>
  <div class="card border shadow-[20] min-h-[320px]">
    <div class="card-body">
      <div class="header_space mb-3">
        <h4 class="text-[15px] font-semibold">Recent transactions </h4>
        <router-link class="font-14 text-[10px] hidden lg:block" :to="{name: 'transaction'}">See all transaction
        </router-link>
        <router-link class="font-14 text-[10px] block lg:hidden" :to="{name: 'transaction'}">All</router-link>
      </div>
      <div class="transaction-widget">
        <div class="row" v-if="loading">
          <shimmer :num="5"/>
        </div>
        <div class="row" v-else>
          <div class="col-md-12" v-if="GET_TRANSACTION.results.length === 0">
            <empty-component :message="message"/>
          </div>
          <div v-else class="col-md-12" v-for="(item,key) in GET_TRANSACTION.results.slice(0,3)" v-bind:key="key">
            <div class="border rounded-[5px] mt-1">
              <div class="flex gap-2 py-[15px] px-[10px]">

                <div class="w-[10%]">
                  <span class="sold-thumb my-[4px]"
                        :class="{'bg-green-500': ['completed','paid','success'].includes(item.status) === true,
                        'bg-red-500': ['failed','terminated'].includes(item.status) === true,
                        'bg-yellow-500': ['pending'].includes(item.status) === true}">
                      <i class="la la-arrow-left font14  rotate-[330deg] font-weight-bolder"
                         style="color: #fff !important"  v-if="item.type === 'transport'"></i>
                      <i class="la la-arrow-left font14 rotate-[120deg]   font-weight-bolder"
                         style="color: #fff !important" v-if="item.type === 'withdrawal'"></i>
                  </span>
                </div>

                <div class="w-[90%]">
                  <div class="flex gap-3">
                    <div class="w-[80%]">
                      <div class="holde__ed" v-if="item.type === 'transport'">
                        <p class="text-[14px] my-[7px]">Transport Charge</p>
                        <p v-if="item.machine" class="text-xs font-weight-bold mt-[3px]">{{ item.machine.display_name }} </p>
                        <small></small>
                      </div>
                      <div class="holde__ed" v-if="item.type === 'withdrawal'">
                        <p class="text-[14px] my-[7px]">Wallet Withdrawal</p>
                      </div>
                    </div>
                    <div class="w-[20%]">
                      <p class="font-weight-bold text-right mt-[2px]">₦{{ item.amount.toLocaleString() }}</p>
                      <span class="text-xs"> {{ item.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import shimmer from "../../reusable/shimmer";
import emptyComponent from "./emptyComponent";

export default {
  name: "TransactionTable",
  components: {shimmer, emptyComponent},
  data: function () {
    return {
      loading: true,
      message: 'No transaction available'
    }
  },
  methods: {
    getTransaction: function () {
      this.loading = true
      this.$store.dispatch('transactions/getTransaction', {data: ``}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_TRANSACTION: 'transactions/GET_TRANSACTIONS'
    })
  },
  mounted() {
    this.getTransaction()
    window.Bus.$on('reload-latest-transaction', () => {
      this.getTransaction()
    })
  },

}
</script>

<style scoped>
.text-description {
  text-align: left;
  margin-left: 44px;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

@media only screen and (max-width: 640px) {
  .sm-text {
    position: relative;
    top: -44px;
  }

  .card__name {
    position: relative;
    top: -38px;
    left: 45px;
  }

  .card__money {
    position: relative;
    top: -27px;
    left: 42px;
  }
}

.header_space {
  display: flex;
  justify-content: space-between;
}

.font-14 {
  font-size: 14px;
}

.holde__ed {
  text-align: start;
  padding-left: 19px;
  line-height: 18px;
}

@media only screen and (max-width: 640px) {
  .card-title {
    font-size: 15px !important;
  }

  .sub-text {
    font-size: 10px !important;
  }

}

</style>