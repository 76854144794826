<template>
  <div class="h-full py-6 px-[20px]">
    <TabComponents :tabs="tabs" @change="switchTab"
                   :css="`flex flex-row flex-wrap overflow-x-auto overflow-y-hidden`"/>
    <div class="pt-[10px]" v-if="tab === 'meters'">
      <div class="block lg:flex lg:justify-between gap-2">
        <div class="w-full flex gap-4">
          <el-button class="float-end h-[40px]" type="primary" icon="el-icon-plus" size="mini"
                     :disabled="loading" v-on:click="addMeter">ADD
            METER
          </el-button>
          <el-input placeholder="Search " class="justify-items-start w-full lg:w-3/12 mb-2 lg:mb-0"
                    v-model="payload.search" @input="onSearch"></el-input>
        </div>
        <div class="">
          <el-select v-model="payload.filter" filterable clearable placeholder="Filter"
                     class="justify-items-end w-full" v-on:change="onSearch">
            <el-option
                v-for="(item,key) in statusFilterList"
                :key="key"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <TableComponent :results="GET_METERS.results" :total_pages="GET_METERS.total_pages" :columns="columns"
                        @onResultChange="changeResultNumber"
                        @onChangePageNumber="changePageNumber"/>
      </div>
    </div>
    <div class="pt-[10px]" v-if="tab === 'setting'">
      <settings/>
    </div>
    <Modal v-if="onOpenMeterForm" :width="600"
           v-bind:title="currentMeter.code ? 'Update Meter' : 'Create Meter'"
           @handleClose="onOpenMeterForm = false">
      <div class="w-full" slot="body">
        <meterForm :meter="currentMeter" @closeModal="onOpenMeterForm = false"/>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>
import * as _ from "lodash"
import TableComponent from "../../../../components/Table"
import Modal from "../../../../components/Modal";
import TabComponents from "../../../../components/Tabs";
import {errorFormat} from "../../../../utils";
import meterForm from "./meterForm";
import Settings from "./Settings";
import {mapGetters} from "vuex";

export default {
  name: "dashboard",
  components: {TableComponent, Modal, meterForm, TabComponents, Settings},
  data: function () {
    return {
      limit: 10,
      tab: 'meters',
      tabs: [
        {
          name: 'Meters',
          active: true,
          slug: 'meters'
        },
        {
          name: 'Settings',
          active: false,
          slug: 'setting'
        },
      ],
      payload: {
        filter: "",
        search: ""
      },
      loading: false,
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'field',
        },
        {
          name: '',
          label: 'Serial Number',
          type: 'custom',
          custom: (value) => {
            if (!value) return "";
            return value.store.serial_number
          }
        },
        {
          name: 'customer',
          label: 'Customer',
          type: 'custom',
          custom: (value) => {
            if (!value) return "";
            if (typeof value === "object") {
              if (!value.customer) return "";
              return `${value.customer.first_name} ${value.customer.last_name}`
            }
            return ""
          }
        },
        {
          name: 'status',
          label: 'Status',
          type: 'bool',
          statusName: {
            active: 'Active',
            inactive: 'Inactive'
          }
        },
        {
          name: '',
          label: '',
          type: 'action',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          methodList: [
            {
              'icon': 'edit',
              title: 'Edit',
              css: 'bg-gray-200 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onEdit(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'delete',
              title: 'Delete',
              css: 'bg-red-500 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onDelete(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'setting',
              title: 'View',
              css: 'bg-white border py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onOpen(item)
              },
              enabled: () => {
                return true
              }
            },
          ],

        },
      ],

      currentMeter: {
        name: '',
        serial_number: '',
        customer_code: '',
        status: true
      },
      onOpenMeterForm: false,
      onSubmitLoading: false,
      statusList: [
        {
          value: true,
          label: 'Activate'
        },
        {
          value: false,
          label: 'Deactivate'
        },
      ],
      statusFilterList: [
        {
          value: true,
          label: 'Active'
        },
        {
          value: false,
          label: 'Inactive'
        },
      ],
    }
  },
  methods: {
    switchTab: function (tab) {
      const {slug} = tab
      this.tabs.forEach((item) => {
        item.active = item.slug === slug;
      })
      this.tab = slug
    },
    addMeter: function () {
      this.currentMeter = {}
      this.onOpenMeterForm = true
    },
    onEdit: function (meter) {
      const raw = JSON.parse(JSON.stringify(meter))
      const customer = _.get(raw, 'customer.code', '')
      const serial_number = _.get(raw, 'store.serial_number', '')
      this.currentMeter = {
        ...raw,
        customer_code: customer,
        serial_number: serial_number
      }
      this.onOpenMeterForm = true
    },
    submitForm: function () {
      const {code} = this.currentMeter
      const merchant = this.GET_USER.merchant.code
      this.onSubmitLoading = true
      if (code) {
        this.$store.dispatch('meter/updateMeter', {
          data: this.currentMeter,
          code: code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenMeterForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter updated successfully"
          })
        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      } else {
        this.$store.dispatch('meter/createMeter', {
          data: this.currentMeter,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenMeterForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter created successfully"
          })
        }).catch(err => {
          this.onSubmitLoading = false
          const message = errorFormat({error: err})

          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      }

    },
    changePageNumber: function (value) {
      let page = `page=${value}&page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&category=${this.payload.filter}`
      }

      this.onMakeRequest(page)
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMeters', {data: page, merchant: merchant}).then(() => {
        this.loading = false
      })
    },
    changeResultNumber: function (value) {
      this.limit = value
      let page = `page_size=${value}`
      if (this.payload.search) {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&category=${this.payload.filter}`
      }
      this.onMakeRequest(page)
    },
    onSearch: function () {
      let page = `page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&status=${this.payload.filter}`
      }
      this.onMakeRequest(page)
    },
    getMeters: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMeters', {data: `page_size=${this.limit}`, merchant: merchant}).then(() => {
        this.loading = false
      }).catch((err) => {
        this.loading = false
        errorFormat({error: err})
      })
    },
    onDelete: function (meter) {
      const merchant = this.GET_USER.merchant.code
      this.$confirm('Do you want to delete this meter, This operation could result to deleting of all the resources attached to this meter.', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('meter/deleteMeter', {merchant: merchant, code: meter.code}).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'meter deleted successfully'
              })
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    onOpen: function (meter) {
      this.$router.push({
        'name': 'meter-detail-overviews',
        params: {code: meter.code, section: 'overview'}
      })
    },
    getCustomers: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('customers/getCustomers', {data: `is_paging=false`, merchant: merchant}).catch(err => {
        errorFormat({error: err})
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METERS: 'meter/GET_METERS',
      GET_CUSTOMERS: 'customers/GET_CUSTOMERS'
    }),

  },
  mounted() {
    this.getMeters()
    this.getCustomers()
  }
}
</script>

<style scoped>

</style>