<template>
  <div>
    <div class="bg-white border shadow-sm rounded-xl ">
      <div class="card-body">
        <h6 class="text-left font-weight-bold">MONTHLY STATISTICS</h6>
        <div data-cy='graph' class="apex-chart-canvas dm-sans-font">
          <apexchart type="line" height="250" :options="options" :series="graphData.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "meterGraph",
  data: function () {
    return {
      serverLineSeries: [
        {
          name: 'Website Blog',
          type: 'area',
          data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
        },
        {
          name: 'Social Media',
          type: 'area',
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        }
      ],
      options: {
        chart: {
          id: 'income-graph',
          height: 200,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          labels: {
            style: {
              colors: '#7987a1',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#7987a1',
            },
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            },
            columnWidth: '30%',
            endingShape: 'rounded',
          }
        },
        colors: ['#0589E8'],
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
        },
        stroke: {
          show: true,
          width: 2,
          endingShape: 'rounded',
          colors: ['transparent'],
        },
        responsive: [{
          breakpoint: 576,
          options: {
            stroke: {
              show: true,
              width: 1,
              endingShape: 'rounded',
              colors: ['transparent'],
            },
          },
        }],
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          floating: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        tooltip: {
          theme: 'dark'
        }
      },
      dateObject: {
        range: '',
        date: '',
      },
      graphData: {
        options: {},
        series: [
          {
            name: 'Income',
            type: 'area',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
        ]
      },
      loading: true
    }
  },

}
</script>

<style scoped>

</style>