<template>

  <div class="row">
    <div class="col-md-6">
      <div class="page_title-content float-left">
        <p>{{ title }}
          <span> {{ subTitle }}</span></p>
        <h3 style="font-weight: bold">{{ header }}</h3>
      </div>
    </div>
    <div class="col-md-6">
      <ul class="text-right breadcrumbs list-unstyle slot-field">
        <li>
          <slot name="field"/>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>

export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    }
  },

}
</script>

<style scoped>
p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #009286;
}

.p-15 {
  padding: 20px 0px 20px;
}

p span {
  line-height: 30px;
  font-weight: 500;
  color: #1F2641;
  margin-left: 10px;
  opacity: 1;
}

.slot-field {
  position: relative;
  top: 29px;
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 12px !important;
  }

}
</style>