<template>
  <div class="flex w-full h-screen overflow-hidden">
    <sidebar/>
    <div class="flex flex-col h-full w-full">
      <custom-header/>
      <div class="h-full w-full overflow-auto bg-mainBG">
        <router-view/>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import customHeader from "./layouts/header";
import sidebar from "./layouts/sidebar";
import isScreen from "../../../helpers/screenHelper";

export default {
  name: "MeterPortalComponent",
  components: {customHeader, sidebar},
  data: function () {
    return {
      openWithdrawForm: false,
      innerDrawer: true,
      loadingWithdrawForm: false,
      logDrawerSize: '40%',
      openPasswordModal: false,
      withdrawForm: {
        amount: 0,
      },
      passwordForm: {
        old_password: '',
        new_password: '',
      },
      confirm_password: ''
    }
  },
  methods: {
    handleClose() {
      this.openWithdrawForm = false
    },
    errorFormat(error, nameMap) {
      try {
        if (Array.isArray(error.response.data.error)) {
          this.$toast.warn({
            title: 'warning',
            message: error.response.data.error.join(',')
          })
        } else if (typeof error.response.data.error === 'object') {
          for (const [key, value] of Object.entries(error.response.data.error)) {
            if (Array.isArray(value)) {
              this.$toast.warn({
                title: 'warning',
                message: nameMap[key] + ': ' + value.join(',')
              })
            } else {

              this.$toast.warn({
                title: 'warning',
                message: value
              })
            }
            return false
          }
        } else {
          this.$toast.warn({
            title: 'warning',
            message: error.response.data.message
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    resize() {
      if (isScreen('xs') || isScreen('sm') || isScreen('md')) {
        this.$store.commit('general/SET_SIDE_BAR', {'status': false})
        this.logDrawerSize = '100%'
      } else {
        this.$store.commit('general/SET_SIDE_BAR', {'status': true})
        this.logDrawerSize = '45%'
      }
    },
    globalReload() {
      this.$store.dispatch('user/GET_ACCOUNT_WALLET')
      this.$store.dispatch('transactions/getTransaction', {data: ``})
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    })
  },
  mounted() {
    this.resize()
    let self = this
    window.Bus.$on('open-withdraw-form', () => {
      this.openWithdrawForm = true
    })
    window.Bus.$on('logout', () => {
      self.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = self.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    })
    window.Bus.$on('open-password-modal', () => {
      this.openPasswordModal = true
    })
    window.Bus.$on('reload-global', () => {
      this.globalReload()
    })
    window.addEventListener("resize", () => {
      self.resize()
    });
  }
}
</script>

<style scoped>

</style>