<template>
  <div class="border-b bg-white shadow-[5px] w-full right">
    <nav class="flex justify-between h-[50px] w-full py-2 px-3 lg:px-0">
      <div class="header-search d-flex align-items-center ">
        <a class="brand-logo mr-3 block lg:hidden" href="">
          <img src="../../../assets/images/cardfare_icon.png" alt="" width="30">
        </a>
      </div>
      <div class="">
        <div class="d-flex align-items-center">
          <div class="profile_log dropdown">
            <div class="user" data-toggle="dropdown">
              <span class="thumb">{{ canAbbr() }}</span>
              <span class="name"><i class="fa fa-angle-down"></i></span>
            </div>
            <div class="dropdown-menu dropdown-menu-right drop-list">
              <div class="flex items-center justify-start px-3 mt-3 mb-3">
                <div class="thumb cpNXCX position-relative"><span class="span-name">AA</span></div>
                <div class="pl-2">
                  <div class="text-base font-medium elipse">{{ GET_USER.first_name }} {{ GET_USER.last_name }}
                  </div>
                  <div class="font-medium text-gray-300 text-sm2 elipse">{{ GET_USER.email }}</div>
                </div>
              </div>
              <hr>
              <div class="px-3 mb-3">
                <a href="javascript:void(0)" class="" v-on:click="signOut">
                  <div class="flex items-center justify-start mt-3">
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12.9 8L1.70005 8M7.30005 4.5V2.75C7.30005 2.28587 7.48442 1.84075 7.81261 1.51256C8.1408 1.18437 8.58592 1 9.05005 1L16.05 1C16.5142 1 16.9593 1.18437 17.2875 1.51256C17.6157 1.84075 17.8 2.28587 17.8 2.75V13.25C17.8 13.7141 17.6157 14.1592 17.2875 14.4874C16.9593 14.8156 16.5142 15 16.05 15L9.40005 15C8.43361 15 7.30005 14.2164 7.30005 13.25V11.5L7.30005 4.5ZM4.50005 4.5L1.00005 8L4.50005 11.5L4.50005 4.5Z"
                          stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    </svg>
                    <div class="pl-3 text-base text-red-300">Sign out</div>
                  </div>

                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {extractAbbreviation} from "../../../utils";

export default {
  name: "CustomerHeader",
  methods: {
    canAbbr: function () {
      const name = `${this.GET_USER.first_name} ${this.GET_USER.last_name}`
      return extractAbbreviation(name)
    },
    signOut: function () {
      this.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = this.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    },
    toggleSidebar() {
      if (this.SIDE_BAR) {
        this.$store.commit('general/SET_SIDE_BAR', {'status': false})

      } else {
        this.$store.commit('general/SET_SIDE_BAR', {'status': true})
      }
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      SIDE_BAR: 'general/getSideBar'
    })
  }
}
</script>

<style scoped>
.icon-menu {
  font-size: 28px;
  margin-left: 6px;
}

.profile_log {
  font-size: 14px !important;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  font-weight: 500;
}

/*.header {*/
/*  left: 213px;*/
/*  position: fixed;*/
/*  right: 0;*/
/*  top: 0;*/
/*  z-index: 1001;*/
/*  height: 60px;*/
/*  background: white;*/
/*  width: 90%;*/
/*}*/
.header {
  left: 235px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  height: 60px;
  background: white;
  width: 89%;
}

.dashboard_log {
  position: relative;
  left: -52px;
}

.cpNXCX {
  border-radius: 100%;
  background-color: rgb(246, 249, 254);
  width: 36px;
  height: 36px;
}

.position-relative {
  position: relative;
}

.span-name {
  position: absolute;
  top: 5px;
  right: 5px;
}

@media only screen and (max-width: 640px) {
  .header {
    background: #ffffff !important;
    padding-bottom: 23px !important;
    width: 100% !important;
    left: 0px;
  }


  .dashboard_log {
    position: relative;
    left: -4px;
  }

  .cpNXCX {

    border-radius: 100%;
    background-color: rgb(246, 249, 254);
  }

  .drop-list {
    width: 236px !important;
    background: rgb(255, 255, 255);
    /*border: 1.5px solid rgb(223, 223, 223);*/
    box-sizing: border-box;
    box-shadow: rgb(12 26 75 / 10%) 0px 0px 1px, rgb(50 50 71 / 8%) 0px 4px 20px -2px;
    border-radius: 4px;
    position: absolute;
    top: 55px;
    right: -41px !important;
  }
}

.pl-2 {
  padding-left: 0.5rem;
}

.drop-list {
  width: 231px;;
  background: rgb(255, 255, 255);
  /*border: 1.5px solid rgb(223, 223, 223);*/
  box-sizing: border-box;
  box-shadow: rgb(12 26 75 / 10%) 0px 0px 1px, rgb(50 50 71 / 8%) 0px 4px 20px -2px;
  border-radius: 4px;
  position: absolute;
  top: 60px;
  right: 0px;
}

.dxPtFr .elipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.font-medium {
  font-weight: 500;
}

.text-base {
  font-size: 14px;
  line-height: 18px;
}


.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 165, 165, var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}

.text-sm2 {
  font-size: 12px;
  line-height: 18px;
}

.text-red-300 {
  color: #FF0000;
}

@media only screen and (min-width: 800px) {
  .menu-icon-div {
    display: none !important;
  }

}
</style>