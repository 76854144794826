<template>
  <div
      class="text-[15px] font-medium text-center pb-2">
    <ul :class="`${css} border-b-2 border-b-gray overflow-x-auto` ">
      <li :class="`cursor-pointer grow hover:border-b-2 hover:border-b-[#8862df] ${tab.active ? 'border-b-2 border-b-[#8862df]' : ''}`"
          v-for="(tab) in tabs"
          :key="tab.slug" v-on:click="onChangeTab(tab)">
        <button
            :class="`focus:outline-none grow py-2 px-4 rounded-none hover:border-none hover:bg-transparent px-[1rem] ${ tab.active ? 'text-[#8862df] hover:text-[#8862df]' : 'hover:bg-gray-200 hover:bg-gray-100 hover:text-black'}`">
          <span>{{
              tab.name
            }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabComponents",
  props: {
    tabs: {
      type: Array,
    },
    currentTab: {
      type: String,
    },
    css: {
      type: String,
    }
  },
  methods: {
    onChangeTab(tab) {
      this.$emit('change', tab)
    }
  }
}
</script>

<style scoped>

</style>