<template>
  <div class="min-h-[650px] py-6 px-[20px] bg-mainBG">
    <div class="block lg:flex lg:justify-between gap-2">
      <div class="w-full flex gap-4">

      </div>
      <div class="">
        <el-select v-model="payload.filter" filterable clearable placeholder="Filter device"
                   style="float: right;margin-bottom: 10px" v-on:change="changeResultNumber">
          <el-option
              v-for="(item,key) in DEVICES.results"
              :key="key"
              :label="item.display_name"
              :value="item.setting.serial_number">
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <TableComponent :results="GET_TRANSACTION.results" :total_pages="GET_TRANSACTION.total_pages" :columns="columns"
                      @onResultChange="changeResultNumber"
                      @onChangePageNumber="changePageNumber"/>
    </div>
  </div>
</template>

<script>

import TableComponent from "../../../components/Table"
import {formatAmount} from "../../../utils";
import {mapGetters} from "vuex";

export default {
  name: "dashboard",
  components: {TableComponent},
  data: function () {
    return {
      loading: true,
      limit: 20,
      message: 'No data available',
      payload: {
        filter: '',
        page: 1
      },
      columns: [
        {
          name: 'reference',
          label: 'Ref.',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            if (!value.reference) {
              return "-"
            }
            return value.reference
          }
        },
        {
          name: 'amount',
          label: 'AMOUNT',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            return `₦${formatAmount(value.amount)}`
          }
        },
        {
          name: 'type',
          label: 'TYPE',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            return value.type
          }
        },
        {
          name: 'machine',
          label: 'DEVICE',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            if (!value.machine) {
              return "-"
            }
            if (!value.machine.setting) {
              return "-"
            }
            return value.machine.setting.serial_number
          }
        },
        {
          name: 'status',
          label: 'Status',
          type: 'customOption',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          options: [
            {
              label: 'Pending',
              customClass: 'bg-yellow-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: "pending"
            },
            {
              label: 'Success',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: "paid"
            },
            {
              label: 'Success',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: "success"
            },
            {
              label: 'Unpaid',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: "unpaid"
            },
            {
              label: 'Failed',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: "failed"
            },
          ]
        },
      ],
    }
  },

  methods: {
    changePageNumber(value) {
      this.loading = true
      let page = `page=${value}&page_size=${this.limit}`
      this.payload.page = value
      if (this.payload.filter !== '') {
        page = page + `&machine_number=${this.payload.filter}`
      }
      this.$store.dispatch('transactions/getTransaction', {data: page}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    changeResultNumber() {
      this.loading = true
      let page = `page=${this.payload.page}&page_size=${this.limit}`
      if (this.payload.filter !== '') {
        page = page + `&machine_number=${this.payload.filter}`
      }
      this.$store.dispatch('transactions/getTransaction', {data: page}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    getTransaction: function () {
      this.loading = true
      this.$store.dispatch('transactions/getTransaction', {data: `is_paging=false`}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    getDeviceList() {
      this.$store.dispatch('device/getDevices', {'params': `?limit=${this.limit}`}).then(() => {
      }).catch(err => {
        if (err.response.status === 401) {
          window.Bus.$emit('open-session-login')
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_TRANSACTION: 'transactions/GET_TRANSACTIONS',
      DEVICES: 'device/GET_DEVICES'
    })
  },
  mounted() {
    this.getTransaction()
    this.getDeviceList()
  }
}
</script>

<style scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0 1rem;
}


.table tr td, .table tr th {
  background: none;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

</style>