<template>
  <div class=" py-6 px-[20px] bg-mainBG">

    <div class="block lg:flex gap-6">
      <div class="w-full mb-4 lg:mb-0 lg:w-[30%]">
        <div class="w-full min-h-[250px] relative lg:sticky lg:top-6 ">
          <div class="shadow-sm border border-[3px] min-h-[250px] py-2 px-6 rounded-lg bg-white">
            <div class="mt-[10px] mb-[20px]">
              <h3 class="font-semibold">Meter Information</h3>
            </div>
            <div class="mb-[10px]">
              <h1 class="text-[0.9rem] font-medium">Meter Name</h1>
              <p class="text-[0.8rem]" v-if="!isLoading">{{ GET_METER.name }}</p>
              <div class="bg-[#e2e8f0] h-4 mt-[2px] w-full animate-pulse" v-else/>
            </div>
            <div class="mb-[10px]">

              <h1 class="text-[0.9rem] font-medium">Meter Serial Number</h1>
              <p class="text-[0.8rem]" v-if="!isLoading">{{ GET_METER.store.serial_number }}</p>
              <div class="bg-[#e2e8f0]  h-4 mt-[2px] w-full animate-pulse" v-else/>
            </div>
            <div class="mb-[10px]">
              <h1 class="text-[0.9rem] font-medium">Customer</h1>
              <p class="text-[0.8rem]" v-if="!isLoading">{{ GET_METER.customer.first_name }}
                {{ GET_METER.customer.last_name }}</p>
              <div class="bg-[#e2e8f0] h-4 mt-[2px] w-full animate-pulse" v-else/>
            </div>
            <div class="mb-[10px] flex items-center justify-between">
              <h1 class="text-[1rem] font-medium">Status</h1>
              <p class="text-[0.8rem] mt-[10px]" v-if="!isLoading">
                <span class="py-1 px-12 rounded-lg font-semibold bg-success" v-if="GET_METER.status">
                  Active
                </span>
                <span class="py-1 px-12 rounded-lg font-semibold bg-red-500" v-if="!GET_METER.status">
                  Inactive
                </span>
              </p>
              <div class="bg-[#e2e8f0] rounded h-8 mt-[2px] w-[50%] animate-pulse" v-else/>
            </div>
            <div class="mt-6 mb-3 flex flex-col gap-2">
              <button v-on:click="onEdit"
                      class="flex-1 bg-mainBG w-full py-2 rounded-lg  text-mainBorder transition-colors duration-300 ease-in-out focus:outline-none mr-2 cursor-pointer"
              >
                <i class=" el-icon-edit mr-2"></i>Edit Meter
              </button>
              <button
                  class="flex-1 py-1 rounded-lg bg-mainPurple text-white py-2 transition-colors duration-300 ease-in-out focus:outline-none"
                  v-on:click="onFactoryReset(GET_METER)"
              >
                Factory Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-[70%] lg:max-w-[70%]">
        <TabComponents :tabs="tabs" @change="switchTab"
                       :css="`flex flex-row flex-wrap overflow-x-auto overflow-y-hidden`"/>
        <div class="">
          <Overview v-if="tab === 'overview'"/>
          <single-meter-source v-if="tab === 'source'"/>
          <meter-table-reading v-if="tab === 'reading'"/>
          <meter-payment v-if="tab === 'payment'"/>
          <meter-actions v-if="tab === 'request'"/>
        </div>
      </div>
    </div>
    <Modal v-if="onOpenMeterForm" :width="600"
           v-bind:title="currentMeter.code ? 'Update Meter' : 'Create Meter'"
           @handleClose="onOpenMeterForm = false">
      <div class="w-full" slot="body">
        <meterForm :meter="currentMeter" @closeModal="onOpenMeterForm = false"/>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>
import * as _ from "lodash";
import Overview from "./components/Overview";
import SingleMeterSource from "./components/SingleMeterSource";
import MeterTableReading from "./components/MeterTableReading";
import meterForm from "./meterForm";
import MeterPayment from "./components/MeterPayment";
import MeterActions from "./components/MeterActions";
import Modal from "../../../../components/Modal";
import TabComponents from "../../../../components/Tabs";
import {mapGetters} from "vuex";
import {errorFormat} from "../../../../utils";

export default {
  name: "MeterOverview",
  components: {
    Overview,
    SingleMeterSource,
    MeterTableReading,
    MeterPayment,
    meterForm,
    Modal,
    TabComponents,
    MeterActions
  },
  data: function () {
    return {
      tab: 'overview',
      currentMeter: {
        name: '',
        serial_number: '',
        customer_code: '',
        status: true
      },
      onOpenMeterForm: false,
      onSubmitLoading: false,
      isLoading: true,
      tabs: [
        {
          name: 'Meter Overview',
          active: true,
          slug: 'overview'
        },
        {
          name: 'Custom Tariff',
          active: false,
          slug: 'source'
        },
        {
          name: 'Meter Reading',
          active: false,
          slug: 'reading'
        },
        {
          name: 'Meter Payment',
          active: false,
          slug: 'payment'
        },
        {
          name: 'Meter Request',
          active: false,
          slug: 'request'
        }
      ]
    }
  },
  methods: {
    onFactoryReset: function (meter) {
      const merchant = this.GET_USER.merchant.code
      this.$confirm('Confirm your request to perform factory reset operation on the meter.', 'Confirmation', {
        confirmButtonText: 'Yes, Reset It',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('meter/meterFactoryResetRequest', {
              merchant: merchant, code: meter.code, data: {
                type: 'factory_reset'
              }
            }).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Meter Factory Reset Operation Schedule. This can take couple of secs.'
              })
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Yes, Reset It';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    switchTab: function (tab) {
      const {slug} = tab
      this.tabs.forEach((item) => {
        item.active = item.slug === slug;
      })
      this.tab = slug
    },
    onEdit: function () {
      if (!this.GET_METER) return;
      const raw = JSON.parse(JSON.stringify(this.GET_METER))
      const customer = _.get(raw, 'customer.code', '')
      const serial_number = _.get(raw, 'store.serial_number', '')
      this.currentMeter = {
        ...raw,
        customer_code: customer,
        serial_number: serial_number
      }
      this.onOpenMeterForm = true
    },
    getSingleMeter: function () {
      const code = this.$route.params.code
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMeter', {
        code: code,
        merchant: merchant
      }).then(() => {
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        errorFormat({error: err})
      })
    }
  },
  mounted() {
    this.getSingleMeter()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METER: 'meter/GET_SINGLE_METER'
    }),
  }
}
</script>

<style scoped>

</style>