<template>
  <div class="container ">
    <div class="row no-gutters mb-7">
      <div class="col-6">
        <PageTitle header="Attendance device"/>
      </div>
      <div class="col-6">
        <div class="form-group filter-holder">
          <el-button type="primary" icon="el-icon-plus" size="mini" :disabled="loading">ADD
            DEVICE
          </el-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-input placeholder="Search " class="search-input"
                  v-model="payload.search"></el-input>
      </div>
      <div class="col-xl-12" v-if="loading === false">
        <div class="table-responsive">
          <table class="table mb-0 table-responsive-sm">
            <thead>
            <tr>
              <th>
                S/N
              </th>
              <th>
                Display name
                <span>
                <i class="fa fa-arrow-up mr-1 icon-sort"></i>
                <i class="fa fa-arrow-down icon-sort"></i>
              </span>
              </th>
              <th>
                Serial number
                <span>
                <i class="fa fa-arrow-up mr-1 icon-sort"></i>
                <i class="fa fa-arrow-down icon-sort"></i>
              </span>
              </th>
              <th>
                Status
              </th>
              <th>
                Date created
              </th>
              <th>

              </th>
            </tr>
            </thead>
            <tbody v-if="[].length > 0">
            <table-list @remove="deleteItem" v-for="(item,key) in []" v-bind:item="item" :key="key"
                        :counter="key"/>
            </tbody>
            <tbody class="" v-else>
            <tr style="background: transparent !important;">
              <td colspan="5">
                <empty-component :message="'You are yet to add a device to your account'"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="col-xl-12" v-else>
        <div>
          <shimmer :num="5"/>
        </div>
      </div>

      <div class="col-md-12 paggy">
               <span class="flex justify-between align-items-center space-x-5 mt-4 float-left">
                                  <span class="text-base"></span>
                                  <span>
                    <el-select v-model="limit" placeholder="Select" size="small" style="width: 69px;"
                               v-on:change="changeResultNumber">
                      <el-option
                          v-for="item in [10,20,30,40,50]"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                    </el-select>
                                  </span>
                              </span>
        <el-pagination class="float-right pag mt-4"
                       background
                       @current-change="changePageNumber"
                       layout="prev, pager, next"
                       :total="1">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../layout/PageTitle";
import emptyComponent from "../components/emptyComponent";
import shimmer from "../../reusable/shimmer";
import tableList from "./include/tableList";

export default {
  name: "Dashboard",
  components: {PageTitle, emptyComponent, shimmer, tableList},
  data: function () {
    return {
      filter: '',
      searchName: '',
      limit: 30,
      payload: {
        search: ''
      },
      loading: true,
      showMiddleAddBtn: true,
      loadingData: false,
      message: 'You are yet to add a device to your account'
    }
  },
  methods: {
    deleteItem(payload) {
      console.log(payload)
      // let itemIndex = this.DEVICES.results.findIndex(item => item.id = payload.id)
      // if (itemIndex > -1) {
      //   this.DEVICES.results.splice(itemIndex, 1)
      // }
    },
    addNewDevice: function () {
      this.$router.push('/attendance/add-device')
    },
    changePageNumber(value) {
      this.loading = true
      let page = `page=${value}&page_size=${this.limit}&account_id=${this.GET_USER.username}`
      if (this.searchName !== '') {
        page = page + `&search=${this.searchName}`
      }
      this.$store.dispatch('card/getCards', {data: page}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    changeResultNumber() {

    },
    getAttendanceList() {
      this.loading = true
      this.$store.dispatch('device/getDevices', {'params': `?limit=${this.limit}`}).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        if (err.response.status === 401) {
          window.Bus.$emit('open-session-login')
        }
      })
    }
  },
}
</script>

<style scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0 1rem;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

.table tr {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  font-size: 14px;
}

.table tr td, .table tr th {
  background: none;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

.search-input {
  width: 20%;
  float: left;
  margin-bottom: 25px
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: left;
  }

  .search-input {
    width: 50% !important;
  }
}

.icon-sort {
  font-size: 10px;
  cursor: pointer;
}

.btn-group-el {
  float: left;
}

.filtering-above-table {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 15px;
}

.filtering-above-table .fl-pagetype {
  display: flex;
  background: #fff;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 20px 0 0;

}

.pd {
  padding: 7px 15px;
}

.pd-right {
  padding: 7px 35px 7px 15px;
}

.filtering-above-table .fl-pagetype > div {
  width: 40px;
}
</style>