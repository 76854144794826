<template>
  <div class="margin-bottom-50">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card shadow border">
          <div class="card-body ">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">Security information</h2>
                  <p class="text-left" style="font-size: 13px;margin-top: 4px">Protect your account by setting a 4
                    secret key.</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2" v-if="GET_MERCHANT_INFO.has_security_key === true">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Previous PIN:</label>
                  <el-input placeholder="Security PIN"  type="password" minlength="4"
                            maxlength="4"
                            class="w-full"
                            v-model="old_security_key" show-password></el-input>
                </div>
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">PIN:</label>
                  <el-input placeholder="Security PIN" type="password" minlength="4"
                            maxlength="4"
                            class="w-full"
                            v-model="payload.security_key" show-password></el-input>
                </div>
              </div>

            </div>
          </div>
        </div>
        <el-button type="primary" native-type="submit" :disabled="loading" v-on:click="submit"
                   v-if="loadingInformation === false"
                   style="margin-bottom: 100px;float: left">
          Update <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import shimmer from "../../reusable/shimmer";
import {mapGetters} from "vuex";

export default {
  name: "security",
  components: {
    shimmer
  },
  data: function () {
    return {
      loadingInformation: false,
      loading: false,
      old_security_key: '',
      payload: {
        security_key: ''
      }
    }
  },
  methods: {
    submit: function () {
      this.loading = true
      let payload = {
        'security_key': this.payload.security_key
      }

      if (this.GET_MERCHANT_INFO.has_security_key === true) {
        if (!this.old_security_key) {
          this.loading = false
          return
        }
        payload['old_security_key'] = this.old_security_key

      }
      if (!this.payload.security_key) {
        this.loading = false
        return
      }
      this.$store.dispatch('user/UPDATE_SECURITY_KEY', {'data': payload, 'username': this.GET_USER.username})
          .then(() => {
            this.loading = false
            this.old_security_key = ''
            this.payload.security_key = ''
            this.$toast.success({
              title: 'success',
              message: `Security pin updated successfully`
            })

          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
  },
  computed: {
    ...mapGetters({
      GET_MERCHANT_INFO: 'user/GET_MERCHANT_INFO',
      GET_USER: 'user/GET_USER',
    }),
  }
}
</script>

<style scoped>

</style>