export const errorFormat = ({error}) => {
    if ([400].includes(error.response.status)) {
        if ('errors' in error.response.data) {
            if (Array.isArray(error.response.data.errors)) {
                return error.response.data.errors.join(',')
            } else if (typeof error.response.data.errors === 'object') {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    if (Array.isArray(value) && key) {
                        return value.join(',')
                    } else {
                        return value
                    }
                }
            } else {
                return error.response.data.errors
            }
        } else if ('message' in error.response.data) {
            if (Array.isArray(error.response.data.message)) {
                return error.response.data.message.join('')
            } else {
                return error.response.data.message
            }
        }
    } else if (error.response.status === 401) {
        return error.response.data.message
    } else if (error.response.status === 403) {
        if ("code" in error.response.data) {
            if (error.response.data.code === "token_not_valid") {
                window.Bus.$emit('logout')
            }
            return "Something went wrong while processing your request";
        }
    } else if (error.response.status === 429) {
        return error.response.data.message
    } else {
        return 'Something went wrong while processing your request'
    }
}
export const formatAmount = (amount) => {
    // Check if the input is a valid number
    if (isNaN(amount)) {
        return 0.0;
    }

    // Use toLocaleString to format the number with commas for thousands separator
    const formattedAmount = amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

    return `${formattedAmount}`;
}

export const extractAbbreviation = (word) => {
    if (!word) {
        return 'OO'
    }
    const splitter = word.split(' ')
    let join_word = []
    splitter.map(item => {
        if (String(item)) {
            join_word.push(String(item).charAt(0))
        }
    })
    return join_word.join('')
}
