<template>
  <div class="border-b shadow w-full right">
    <nav class="flex justify-between h-[50px] w-full py-2 px-3 lg:px-0">
      <div class="header-search d-flex align-items-center ">
        <a class="brand-logo mr-3 block lg:hidden" href="">
          <img src="../../../../assets/images/cardfare_icon.png" alt="" width="30">
        </a>
      </div>
      <div class="">
        <div class="d-flex align-items-center gap-2">
          <div class="profile_log dropdown">
            <div class="user" data-toggle="dropdown">
              <span class="thumb">{{ canAbbr() }}</span>
              <span class="name"><i class="fa fa-angle-down"></i></span>
            </div>
            <div class="dropdown-menu dropdown-menu-right drop-list">
              <div class="flex items-center justify-start px-3 mt-3 mb-3">
                <div class="thumb rounded-full border p-1"><span class="span-name">{{ canAbbr() }}</span></div>
                <div class="pl-2">
                  <div class="text-sm font-medium truncate">{{ GET_USER.first_name }} {{ GET_USER.last_name }}
                  </div>
                  <span class="font-medium text-gray-300 text-xs truncate">{{ GET_USER.email }}</span>
                </div>
              </div>
              <hr>
              <div class="px-3 mb-3">
                <a href="javascript:void(0)" class="" v-on:click="signOut">
                  <div class="flex items-center justify-start mt-3">
                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12.9 8L1.70005 8M7.30005 4.5V2.75C7.30005 2.28587 7.48442 1.84075 7.81261 1.51256C8.1408 1.18437 8.58592 1 9.05005 1L16.05 1C16.5142 1 16.9593 1.18437 17.2875 1.51256C17.6157 1.84075 17.8 2.28587 17.8 2.75V13.25C17.8 13.7141 17.6157 14.1592 17.2875 14.4874C16.9593 14.8156 16.5142 15 16.05 15L9.40005 15C8.43361 15 7.30005 14.2164 7.30005 13.25V11.5L7.30005 4.5ZM4.50005 4.5L1.00005 8L4.50005 11.5L4.50005 4.5Z"
                          stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    </svg>
                    <div class="pl-3 text-sm text-red-300">Sign out</div>
                  </div>

                </a>
              </div>
            </div>
          </div>
          <div class="visible lg:hidden"><span class="">
            <i class="mdi mdi-menu icon-menu text-[24px]"
               v-on:click="toggleSidebar"
               v-if="this.SIDE_BAR === false">

          </i>
                <i class="mdi mdi-close-circle-outline icon-menu text-[24px]" v-on:click="toggleSidebar"
                   v-if="this.SIDE_BAR === true"></i>
          </span>
          </div>
        </div>
      </div>
    </nav>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {extractAbbreviation} from "../../../../utils";

export default {
  name: "CustomerHeader",
  methods: {
    canAbbr: function () {
      const name = `${this.GET_USER.first_name} ${this.GET_USER.last_name}`
      return extractAbbreviation(name)
    },
    signOut: function () {
      this.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = this.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    },
    toggleSidebar() {
      if (this.SIDE_BAR) {
        this.$store.commit('general/SET_SIDE_BAR', {'status': false})

      } else {
        this.$store.commit('general/SET_SIDE_BAR', {'status': true})
      }
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      SIDE_BAR: 'general/getSideBar'
    })
  }
}
</script>

<style scoped>


</style>