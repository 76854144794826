<template>
  <div>
    <form v-on:submit.prevent="submitForm">
      <div class="w-full">
        <div class="mt-3">
          <label class="mr-sm-2 label">Tariff</label>
          <el-select v-model="currentTariff.tariff_code" placeholder="Tariff"
                     class="justify-items-end w-full">
            <el-option
                v-for="(item,key) in GET_METER_TARIFFS.results"
                :key="key"
                :label="`${item.name} (${item.category})`"
                :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="">
          <el-button type="primary" native-type="submit" class="mt-4"
                     :disabled="onSubmitLoading">Submit<i
              class="el-icon-loading ml-2" v-if="onSubmitLoading"></i>
          </el-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {errorFormat} from "../../../../../utils";

export default {
  name: "MeterTariffForm",
  props: {
    meter_code: {
      type: String
    },
    tariff: {
      type: Object,
    }
  },
  data: function () {
    return {
      onSubmitLoading: false,
      activeStatus: [
        {
          name: "Activate",
          value: true
        },
        {
          name: "Deactivate",
          value: false
        },
      ],
      currentTariff: {
        tariff_code: '',
        active: false
      }
    }
  },
  methods: {
    submitForm: function () {
      const {code} = this.currentTariff
      const merchant = this.GET_USER.merchant.code
      this.onSubmitLoading = true
      if (code) {
        this.$store.dispatch('meter_tariff/updateTariff', {
          data: this.currentTariff,
          code: code,
          meter_code: this.meter_code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenMeterForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter Tariff updated successfully"
          })
          window.Bus.$emit("refresh-meter-tariff")
        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })

        })
      } else {
        this.$store.dispatch('meter_tariff/createTariff', {
          data: this.currentTariff,
          meter_code: this.meter_code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter Tariff created successfully"
          })
          window.Bus.$emit("refresh-meter-tariff")
        }).catch(err => {
          this.onSubmitLoading = false
          const message = errorFormat({error: err})

          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      }


    },
    getTariffs: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getTariffs', {data: `is_paging=false`, merchant: merchant}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.currentTariff = JSON.parse(JSON.stringify(this.tariff))
    this.getTariffs()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_METER_TARIFFS: 'meter/GET_TARIFFS'
    }),
  }
}
</script>

<style scoped>

</style>