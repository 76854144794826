<template>
  <tr>
    <td>
      {{ counter + 1 }}
    </td>
    <td>
      <span>{{ item.display_name }}</span>
    </td>
    <td>
      <span> {{ item.setting.serial_number }}</span>

    </td>
    <td>
      <div class="holde__ed">
        <label class="toggle">
          <input class="toggle-checkbox" :id="`device_${counter}`" type="checkbox" @input="toggle_checkbox($event)"
                 :checked="item.status" v-on:change="!item.status">
          <div class="toggle-switch"></div>
        </label>
      </div>
    </td>
    <td>
      <span>{{ item.created_at }}</span>
    </td>
    <td>
      <i class="el-icon-edit mr-2 cursor-pointer" v-on:click="onEdit"></i>
      <i class="el-icon-delete cursor-pointer" v-on:click="onDelete"></i></td>
  </tr>
</template>

<script>

export default {
  name: "tableList",
  props: {
    item: {
      type: Object,
      required: true,
    },
    counter: {
      required: true
    }
  },
  data: function () {
    return {
      device: {}
    }
  },
  methods: {

    toggle_checkbox: function (event) {
      this.device.status = event.target.checked
    },
    onDelete() {
      this.$confirm('This will permanently device and associated data from the system?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('device/deleteDevice', {'id': this.device.id}).then(() => {
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Device deleted successfully'
              })
              this.$emit('remove', {id: this.device.id})
            }).catch(er => {
              loading.close()
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              if (er.response.status !== 401) {
                this.$toast.warn({
                  title: 'warning',
                  message: er.response.data.message
                })
              } else {
                window.Bus.$emit('open-login-session')
              }
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    onEdit() {
      this.$router.push({name: 'device-setting', params: {'id': this.item.id}})
    },
  },
  mounted() {
    this.device = this.item
  }
}
</script>

<style scoped>

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

/*.table tr {*/
/*  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);*/
/*  background: #ffffff;*/
/*  font-size: 14px;*/
/*}*/

.table tr td, .table tr th {
  background: none;
  font-size: 14px;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: left;
  }

}

.toggle {
  margin-bottom: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>