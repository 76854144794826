<template>
  <div class="flex gap-3 cursor-pointer py-[10px] px-[27px]" v-on:click="onChange">
                  <span v-if="tab.verified">

<svg width="20" height="21" viewBox="0 0 20 21" fill="none" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg"><path
    d="M.625 10.5a9.375 9.375 0 1018.75 0 9.375 9.375 0 00-18.75 0v0z" fill="#1CA888" stroke="#1CA888"
    stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 10.659l3.226 3.174L15 7.167"
                                                                                    stroke="#fff" stroke-width="1.25"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"></path></svg>
                  </span>
    <span v-else class="h-6 w-6 rounded-full border !border-[#8862df] !border-2 text-center">
                                {{ index + 1 }}
                                </span>
    <span class="py-[2px] font-[400]">
                    {{ tab.name }}
                  </span>
  </div>
</template>

<script>
export default {
  name: "getStartedList",
  props: {
    tab: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.tab.key)
    }
  }
}
</script>

<style scoped>

</style>