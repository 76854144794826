<template>
  <div class="min-h-[650px] py-6 px-[25px] bg-mainBG">
    <div class="flex flex-row justify-between">
      <h4 class="font-semibold">Device Detail</h4>
      <div>
        <el-button type="primary" icon="el-icon-arrow-left" v-on:click="goBack" size="mini">BACK</el-button>
      </div>
    </div>
    <device-detail v-if="tab === 0"/>
  </div>
</template>

<script>

import DeviceDetail from "./include/DeviceDetail";

export default {
  name: "DeviceSetting",
  components: {DeviceDetail},
  data: function () {
    return {
      tab: 0
    }
  },
  methods: {
    goBack() {
      this.$router.push('/devices')
    }
  },

}
</script>