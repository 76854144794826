<template>
  <section class="mt-10">
    <h4 class="font-medium text-[13px]">OVERVIEW</h4>

    <div class="w-full flex gap-5 flex-col-reverse md:flex-row my-3 h-auto">

      <div class="hidden rounded-lg shadow-sm p-4 bg-white w-full relative md:w-[60%] flex flex-col justify-between">
        <p class="text-mainYellow font-medium text-[0.9rem] tracking-wide">
          AVAILABLE FUNDS IN YOUR METER ACCOUNT
        </p>

        <h1 class="mt-5 font-bold text-[1.5rem]">NGN {{ wallet.amount }}</h1>
        <div class="flex justify-end mt-1 font-medium">
          <small class="text-[0.65rem]">
            Last updated: {{ dateFormat(wallet.last_amount_updated) }}
          </small>
        </div>
      </div>

      <div class="rounded-lg shadow-sm p-4 bg-white w-full lg:w-1/3">
        <p class="text-mainYellow font-medium text-[0.9rem] tracking-wide">
          CURRENT METER UNIT
        </p>
        <div class="mt-5 flex items-center justify-between">
          <h1 class="font-bold text-[1.5rem]">{{ wallet.unit }}</h1>
          <p class="text-mainBorder text-[1rem]">UNITS</p>
        </div>

        <small class="font-medium mt-2 text-[0.65rem]">
          Last updated: {{ dateFormat(wallet.last_unit_updated) }}
        </small>
      </div>

    </div>

  </section>
</template>

<script>
import {errorFormat, formatAmount} from "../../../../../utils";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "SingleMeterOverview",
  data: function () {
    return {
      meterSources: [],
      wallet: {
        amount: 0.0,
        unit: 0.0,
        last_amount_updated: "",
        last_unit_updated: "",
      },

    }
  },
  methods: {
    dateFormat: function (dt_created) {
      if (!dt_created) {
        return ''
      }
      return moment(dt_created).format('YYYY-MM-DD')
    },
    getMeterWallet: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getMeterWallet', {
        meter_code: this.$route.params.code,
        merchant: merchant
      }).then(resp => {
        this.wallet = {
          ...resp.data,
          amount: formatAmount(resp.data.amount),
        }
      }).catch((err) => {
        errorFormat({error: err})
      })
    },
    getMeterReading: function () {

    }
  },
  mounted() {
    this.getMeterWallet()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    }),
  }
}
</script>

<style scoped>

</style>