<template>
  <div style="background-color:#f9f9fb !important;">
    <div class="v-application v-application--is-ltr theme--light">
      <div class="v-application--wrap">
        <div class="v-main login">
          <div class="v-main__wrap">
            <div class="container-fluid fill-height">
              <div class="row pl-0 fill-height">
                <div class="form-wrapper col-md-5 col-12 auth-inner">
                  <div class="login-wrapper">
                    <img src="./../../assets/images/cardfare_bg_2.png" data-selenium="logo"
                         class="logo" style="height: 72px"
                         alt="logo">

                    <div class="lds-holder" v-if="loading">
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div class="lds-holder" v-else>
                      <div v-if="res === true">
                        <p class="preamble text-left mb-4">
                          Bank account successfully verified,Kindly proceed to login
                        </p>
                        <div class="form-group" style="margin-top: 27px !important;">
                          <router-link :to="{name:'login'}"
                                       class="mb-2  btn btn-block __css height text-white primary__color"
                                       style=" margin-bottom: 12px !important;   margin-top: 20px !important;text-align: center !important;font-size: .875rem">

                            Login
                          </router-link>
                        </div>
                      </div>
                      <div v-if="res === false">
                        <p class="preamble text-left mb-4">
                          Account verification not successful,Kindly make sure you click the link sent to your email or
                          copy the right link.
                        </p>
                        <div class="form-group" style="margin-top: 27px !important;">
                          <router-link :to="{name:'login'}"
                                       class="mb-2  btn btn-block __css height text-white primary__color"
                                       style=" margin-bottom: 12px !important;   margin-top: 20px !important;text-align: center !important;font-size: .875rem">

                            Login
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="site-info-wrapper"><span>Copyright ©</span>
                      {{ year }}
                      <a href="https://www.cardfare.ng"><span id="__name">Cardfare</span></a></div>
                  </div>

                </div>
                <div class="login-content-wrapper d-none d-md-flex col-md-7 col-12">
                  <div class="login-content">
                    <img src="../../assets/images/illustration.png" class="home-illustration">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bank_verification",
  data: function () {
    return {
      year: new Date().getFullYear(),
      section: 1,
      resendForm: {
        email: ''
      },
      token: {
        first: '',
        second: '',
        third: '',
        four: '',
        five: '',
        six: '',
      },
      loading: true,
      res: true,
      payload: {
        token: ''
      }
    }
  },
  mounted() {
    if (this.$route.params.token !== '' && this.$route.params.token !== undefined && this.$route.params.token !== null) {
      this.$store.dispatch('auth/token_verification', {
        'status': 'bank_verification',
        'token': this.$route.params.token
      })
          .then(() => {
            this.loading = false
            let self = this
            this.res = true
            setTimeout(function () {
              self.$router.push({'name': 'login'})
            }, 5000)
          })
          .catch(() => {
            this.loading = false
            this.res = false
          })
    }
  }
}
</script>
<style scoped>
/*#54a8c7*/
/*#45c4a0*/
.font-weight-800 {
  font-weight: 800;
}

.no-border-radius {
  border-radius: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-7 {
  margin-top: 7px !important;
}

.theme--light.v-application {
  background: #fff;
  color: rgba(0, 0, 0, .87);
}

.v-application {
  display: flex;
}

.v-application--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.login-page .v-content, .login-page .v-main {
  padding: 0 !important;
}

main.login, .login {
  background-color: #ffffff !important;
}

.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4, 0, .2, 1);
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}

@media screen and (max-height: 640px) {
  .login-page .v-content .container, .login-page .v-main .container {
    margin-bottom: 55px;
  }

  .login-wrapper {
    margin: 0 auto;
  }

  .auth-inner:before {
    display: none
  }

  .logo {
    height: 59px !important;
    margin: 0 auto 81px 0 !important;
  }

  .register-text {
    text-align: center !important;
    float: none !important;
  }

  .reset-password {
    float: none !important;
    text-align: center;
  }

  .site-info-wrapper {
    color: #777;
    font-size: 12px;
    align-self: center !important;
    margin-top: 0px !important;
    position: relative;
    top: 59px !important;
    text-align: center;
  }

  .form-wrapper h1 {
    color: #282947;
    text-transform: none;
    margin-bottom: 12px !important;
    font-size: 23px !important;
  }

  .preamble {
    font-size: 12px !important;
  }

  .mt-sm-8 {
    margin-top: 8px;
  }
}


.login-page .v-content .container, .login-page .v-main .container {
  margin: 0 !important;
  padding: 0 !important;
}

.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100% + 24px);
}

.login-page .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-application .pl-0 {
  padding-left: 0 !important;
}

.login-wrapper {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  height: 100vh;
}

.logo {
  margin: 0 auto 73px 0;
  display: block;
  padding-top: 30px;
}

.form-wrapper h1 {
  color: #282947;
  text-transform: none;
  margin-bottom: 25px;
  font-size: 27px;
  font-weight: 600;
  line-height: 1.4;


}

#app {
  margin-top: 0px !important;
}

.preamble {
  font-size: 15px;
  color: #666;
  margin-bottom: 0px;
  font-weight: 600;
}


.auth-inner:before {
  width: 244px;
  height: 243px;
  content: ' ';
  position: absolute;
  top: 457px;
  left: -46px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC)
}

.login-content-wrapper {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ebe9fc;
  height: 759px !important;
}

.login-content-wrapper .login-content {
  position: relative;
  padding: 15px;
  width: 100%;
}


#__name {
  color: #54a8c7;
  font-weight: 600;
}

.home-illustration {
  max-width: 780px;
  min-width: 300px;
}

.reset-password {
  margin-bottom: 12px !important;
  font-size: .875rem;
  margin-top: 10px !important;
  float: left;
}

.register-text {
  text-align: right;
  margin-bottom: 12px !important;
  font-size: .875rem;
  margin-top: 10px !important;
  float: right;
}

.site-info-wrapper {
  color: #777;
  font-size: 12px;
  text-align: right;
  margin-top: auto;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #24bed3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #24bed3 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-holder {
  margin-top: 86px;
}

.height {
  height: 45px;
}

.__css {
  border-radius: 5px !important;
  font-weight: 500;
  margin-top: 17px
}
</style>
<style scoped>
.font-weight-800 {
  font-weight: 800;
}

.no-border-radius {
  border-radius: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-7 {
  margin-top: 7px !important;
}

.theme--light.v-application {
  background: #fff;
  color: rgba(0, 0, 0, .87);
}

.v-application {
  display: flex;
}

.v-application--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.login-page .v-content, .login-page .v-main {
  padding: 0 !important;
}

main.login, .login {
  background-color: #ffffff !important;
}

.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4, 0, .2, 1);
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}

@media screen and (max-height: 640px) {
  .login-page .v-content .container, .login-page .v-main .container {
    margin-bottom: 55px;
  }

  .login-wrapper {
    margin: 0 auto;
  }

  .auth-inner:before {
    display: none
  }

  .logo {
    height: 59px !important;
    margin: 0 auto 81px 0 !important;
  }

  .register-text {
    text-align: center !important;
    float: none !important;
  }

  .reset-password {
    float: none !important;
    text-align: center;
  }

  .site-info-wrapper {
    color: #777;
    font-size: 12px;
    align-self: center !important;
    margin-top: 0px !important;
    position: relative;
    top: 59px !important;
    text-align: center;
  }

  .form-wrapper h1 {
    color: #282947;
    text-transform: none;
    margin-bottom: 12px !important;
    font-size: 23px !important;
  }

  .preamble {
    font-size: 12px !important;
  }

  .mt-sm-8 {
    margin-top: 8px;
  }
}


.login-page .v-content .container, .login-page .v-main .container {
  margin: 0 !important;
  padding: 0 !important;
}

.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100% + 24px);
}

.login-page .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-application .pl-0 {
  padding-left: 0 !important;
}

.login-wrapper {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.logo {
  margin: 0 auto 97px 0;
  display: block;
  padding-top: 30px;
}

.form-wrapper h1 {
  color: #282947;
  text-transform: none;
  margin-bottom: 25px;
  font-size: 27px;
  font-weight: 600;
  line-height: 1.4;

}

#app {
  margin-top: 0px !important;
}

.preamble {
  font-size: 15px;
  color: #666;
  margin-bottom: 0px;
  font-weight: 600;
}


.auth-inner:before {
  width: 244px;
  height: 243px;
  content: ' ';
  position: absolute;
  top: 457px;
  left: -46px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC)
}

.login-content-wrapper {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ebe9fc;
  height: 759px !important;
}

.login-content-wrapper .login-content {
  position: relative;
  padding: 15px;
  width: 100%;
}

.site-info-wrapper {
  color: #777;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 20px;
  position: relative;
  top: 273px;
  text-align: right;
}

#__name {
  color: #8862df;
  font-weight: 600;
}

.home-illustration {
  max-width: 780px;
  min-width: 300px;
}

@media only screen and (max-width: 600px) {
  .site-info-wrapper {
    top: 62px !important;
  }
}
</style>