<template>
  <div class="h-full py-6 px-[25px] bg-mainBG">
    <div class="block lg:flex lg:justify-between gap-2">
      <div class="w-full flex gap-4">
        <el-button class="float-end h-[40px]" type="primary" icon="el-icon-plus" size="mini" v-on:click="addTariff"
                   :disabled="loading">ADD
          TARIFF
        </el-button>
        <el-input placeholder="Search " class="justify-items-start w-full lg:w-3/12 mb-2 lg:mb-0"
                  v-model="payload.search" @input="onSearch"></el-input>
      </div>
      <div class="">
        <el-select v-model="payload.filter" filterable clearable placeholder="Filter"
                   class="justify-items-end w-full" v-on:change="onSearch">
          <el-option
              v-for="(item,key) in tariffFilterList"
              :key="key"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="w-full">
      <TableComponent :results="GET_TARIFFS.results" :total_pages="GET_TARIFFS.total_pages" :columns="columns"
                      @onResultChange="changeResultNumber" @onChangePageNumber="changePageNumber" @onEdit="onEdit"
                      @onDelete="onDelete"/>
    </div>
    <Modal v-if="onOpenTariffForm" :width="600"
           v-bind:title="currentTariff.code ? 'Update Tariff' : 'Create Tariff'"
           @handleClose="onOpenTariffForm = false">
      <div class="w-full" slot="body">
        <form v-on:submit.prevent="submitForm">
          <div class="w-full">
            <div class="mt-3">
              <label class="mr-sm-2 label">Name</label>
              <el-input placeholder="Name" type="text" class="w-100"
                        v-model="currentTariff.name" required></el-input>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Category</label>
              <el-select v-model="currentTariff.category" filterable clearable placeholder="Category"
                         class="justify-items-end w-full">
                <el-option
                    v-for="(item,key) in tariffFilterList"
                    :key="key"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class=" mt-3">
              <label class="mr-sm-2 label">Rate
              </label>
              <el-input placeholder="Rate" type="number" required class="w-100"
                        v-model="currentTariff.rate"></el-input>
            </div>

            <div class=" mt-3" v-if="currentTariff.category">
              <el-checkbox v-model="currentTariff.default" class="text-wrap flex ">Set this tariff as the default to use
                when the meter is currently running on {{ currentTariff.category }}.
              </el-checkbox>
            </div>
            <div class="">
              <el-button type="primary" native-type="submit" class="mt-4"
                         :disabled="onSubmitLoading">Submit<i
                  class="el-icon-loading ml-2" v-if="onSubmitLoading"></i>
              </el-button>
            </div>
          </div>
        </form>
      </div>
      <div slot="footer" class="flex space-x-5">

      </div>
    </Modal>
  </div>
</template>

<script>
import TableComponent from "../../../../components/Table"
import {errorFormat} from "../../../../utils";
import Modal from "../../../../components/Modal";
import {mapGetters} from "vuex";

export default {
  name: "dashboard",
  components: {TableComponent, Modal},
  data: function () {
    return {
      payload: {
        filter: "",
        search: ""
      },
      loading: false,
      columns: [
        {
          name: 'name',
          label: 'NAME',
          type: 'field',
          customClass: 'm-w-[200px] lg:m-w-min-w-full'
        },
        {
          name: 'category',
          label: 'CATEGORY',
          type: 'field',
          customClass: 'm-w-[200px] lg:m-w-min-w-full'
        },
        {
          name: 'rate',
          label: 'RATE',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            return value.rate
          }
        },
        {
          name: 'default',
          label: 'Default',
          type: 'custom',
          custom: (value) => {
            if (value.default) {
              return "default"
            }
            return ""
          }
        },
        {
          name: '',
          label: '',
          type: 'action',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          methodList: [
            {
              'icon': 'edit',
              title: 'Edit',
              css: 'bg-gray-200 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onEdit(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'delete',
              title: 'Delete',
              css: 'bg-red-500 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onDelete(item)
              },
              enabled: () => {
                return true
              }
            },
          ],
        },
      ],
      currentTariff: {
        category: "phcn"
      },
      onSubmitLoading: false,
      onOpenTariffForm: false,
      limit: 20,
      tariffFilterList: [
        {
          label: 'Phcn',
          value: 'phcn'
        },
        {
          label: 'Solar',
          value: 'solar'
        },
        {
          label: 'Generator',
          value: 'generator'
        }
      ],
      currentPage: 1,
      filterParam: {
        search: "",
        filter: ""
      }

    }
  },
  methods: {
    addTariff: function () {
      this.currentTariff = {}
      this.onOpenTariffForm = true
    },
    onEdit: function (tariff) {
      this.currentTariff = JSON.parse(JSON.stringify(tariff))
      this.onOpenTariffForm = true
    },
    submitForm: function () {
      const {code} = this.currentTariff
      const merchant = this.GET_USER.merchant.code
      this.onSubmitLoading = true
      if (code) {
        this.$store.dispatch('meter/updateTariff', {
          data: this.currentTariff,
          code: code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenTariffForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Tariff updated successfully"
          })

          const page = this.computeParam()
          this.onMakeRequest(page)

        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      } else {
        this.$store.dispatch('meter/createTariff', {
          data: this.currentTariff,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenTariffForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Tariff created successfully"
          })
          this.getTariffs()
        }).catch(err => {
          this.onSubmitLoading = false
          const message = errorFormat({error: err})

          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      }

    },
    computeParam: function () {
      let page = `page=${this.currentPage}&page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&status=${this.payload.filter}`
      }
      return page
    },
    changePageNumber: function (value) {
      this.currentPage = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getTariffs', {data: page, merchant: merchant}).then(() => {
        this.loading = false
      })
    },
    changeResultNumber: function (value) {
      this.limit = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onSearch: function () {
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    getTariffs: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getTariffs', {data: `page_size=${this.limit}`, merchant: merchant}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onDelete: function (tariff) {
      const merchant = this.GET_USER.merchant.code
      this.$confirm('Do you want to delete this tariff', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('meter/deleteTariff', {merchant: merchant, code: tariff.code}).then(() => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'tariff deleted successfully'
              })
              this.getTariffs()
            }).catch(er => {

              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              loading.close()
              const message = errorFormat({error: er})
              this.$toast.error({
                title: 'error',
                message: message
              })
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_TARIFFS: 'meter/GET_TARIFFS'
    }),

  },
  mounted() {
    this.getTariffs()
  }
}
</script>

<style scoped>

</style>