<template>
  <div class="min-h-[650px] py-[40px] px-[37px]">
    <div class="row no-gutters mb-7">
      <div class="col-6">
        <PageTitle header="Withdrawals"/>
      </div>
      <div class="col-6">
        <div class="form-group filter-holder">
          <el-button type="primary" icon="el-icon-plus" v-on:click="CreateNewWithdraw" class="!outline-none" size="mini" :disabled="loading">
            CREATE
          </el-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-input placeholder="Search " class="search-input"
                  v-model="payload.search"></el-input>
      </div>
      <div class="col-xl-12" v-if="loading === false">
        <div class="table-responsive">
          <table class="table mb-0 table-responsive-sm">
            <thead>
            <tr>
              <th style="width: 24%;" class="text-left">
                Ref.
              </th>
              <th>
                Amount
                <!--                <span>-->
                <!--                <i class="fa fa-arrow-up mr-1 icon-sort"></i>-->
                <!--                <i class="fa fa-arrow-down icon-sort"></i>-->
                <!--              </span>-->
              </th>
              <th>
                Status
              </th>
              <th>
                Date created
              </th>
            </tr>
            </thead>
            <tbody v-if="WITHDRAWALS.results.length > 0">

            <tablelist @remove="deleteItem" v-for="(item,key) in WITHDRAWALS.results" v-bind:item="item" :key="key"
                       :counter="key"/>
            </tbody>
            <tbody class="" v-else>
            <tr style="background: transparent !important;">
              <td colspan="4">
                <empty-component :message="'You are yet to create a withdrawal request'"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="col-xl-12" v-else>
        <div>
          <shimmer :num="5"/>
        </div>
      </div>

      <div class="col-md-12 paggy">
               <span class="flex justify-between align-items-center space-x-5 mt-4 float-left">
                                  <span class="text-base"></span>
                                  <span>
                    <el-select v-model="limit" placeholder="Select" size="small" style="width: 69px;"
                               v-on:change="changeResultNumber">
                      <el-option
                          v-for="item in [10,20,30,40,50]"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                    </el-select>
                                  </span>
                              </span>
        <el-pagination class="float-right pag mt-4"
                       background
                       @current-change="changePageNumber"
                       layout="prev, pager, next"
                       :total="1">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import shimmer from "../../reusable/shimmer";
import emptyComponent from "../components/emptyComponent";
import {mapGetters} from "vuex";
import tablelist from "./tablelist";
import PageTitle from "../../../layout/PageTitle";
export default {
  name: "dashboard",
  components: {shimmer, emptyComponent, tablelist, PageTitle},
  data: function () {
    return {
      payload: {search: ''},
      loading: true,
      limit: 30
    }
  },
  methods: {

    changeResultNumber: function () {
      console.log("am here")
    },
    changePageNumber: function () {
      console.log("am here")
    },
    deleteItem: function () {

    },
    CreateNewWithdraw: function () {
    window.Bus.$emit('open-withdraw-form')
    },
    getWithdrawals: function () {
      this.loading = true
      this.$store.dispatch('withdraw/getWithdrawals', {'params': `?page_size=${this.limit}`}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      WITHDRAWALS: 'withdraw/GET_WITHDRAWALS'
    })
  },
  mounted() {
    this.getWithdrawals()
    window.Bus.$on('reload-withdraw-table',()=>{
      this.getWithdrawals()
    })
  }
}
</script>

<style scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0 1rem;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

.table tr {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  font-size: 14px;
}

.table tr td, .table tr th {
  background: none;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

.search-input {
  width: 20%;
  float: left;
  margin-bottom: 25px
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: right;
  }

  .search-input {
    width: 50% !important;
  }
}

.icon-sort {
  font-size: 10px;
  cursor: pointer;
}
</style>