<template>
  <div class="border-r h-full fixed z-[1]  lg:relative py-[20px] overflow-y-scroll px-0 bg-white min-w-[240px]"
       :class="{'visible transition-all duration-500 transform z-[1]': SIDE_BAR === true, 'hidden -translate-x-full ':SIDE_BAR === false}">
    <div class="flex justify-center w-[80%] mx-auto border shadow-sm rounded">
      <div class="flex p-2 cursor-pointer flex-col gap-2 ">
        <h2 class="text-sm font-medium text-gray-400">{{ getName() }}</h2>
        <p class="text-xs text-gray-300">{{ GET_USER.username }}</p>
      </div>
    </div>
    <div class="mt-8">
      <ul class="w-full">
        <li class="pb-[10px] w-full">
          <router-link :to="{name:'meter-overview'}"
                       :class="`${$route.name === 'meter-overview' ? 'border-l-[6px] border-l-[#8862df] text-black' : ''} w-full block text-[15px] px-[25px] py-3 text-start`">
            <span class="nav-text">Overview</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{name:'meter-customer'}"
                       :class="`${$route.name === 'meter-customer' ? 'border-l-[6px] border-l-[#8862df]' : ''} w-full block text-[15px] px-[25px] py-3 text-start`">
            <span class="nav-text">Customers</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{name:'meter-dashboard'}"
                       :class="`${['meter-dashboard','meter-detail-overviews'].includes($route.name) ? 'border-l-[6px] border-l-[#8862df]' : ''} w-full block text-[15px] px-[25px] py-3 text-start`">
            <span class="nav-text">Meters</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{name:'meter-tariff'}"
                       :class="`${$route.name === 'meter-tariff' ? 'border-l-[6px] border-l-[#8862df]' : ''} w-full block text-[15px] px-[25px] py-3 text-start`">
            <span class="nav-text">Tariffs</span>
          </router-link>
        </li>
        <li class="">
          <router-link :to="{name:'meter-transactions'}"
                       :class="`${$route.name === 'meter-transactions' ? 'border-l-[6px] border-l-[#8862df]' : ''} w-full block text-[15px] px-[25px] py-3 text-start`">
            <span class="nav-text">Transactions</span>
          </router-link>
        </li>
        <li class="">
          <router-link
              :class="`${$route.name === 'meter-settings' ? 'border-l-[6px] border-l-[#8862df]' : ''} w-full block text-[15px] px-[25px] py-3 text-start`"
              :to="{name: 'meter-settings'}">
            <span class="nav-text">Setting</span>
          </router-link>
        </li>
        <li v-on:click="signOut">
          <a class="w-full block text-[15px] px-[25px] py-3 text-start" href="javascript:void(0)">
            <span class="nav-text">Sign out</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "sidebar",
  data: function () {
    return {
      currentDate: '',
      name: '',
      isMeterNavOpen: false
    }
  },
  mounted() {
    this.currentDate = new Date().getFullYear()
    this.getProfile()

  },
  methods: {
    signOut: function () {
      this.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = this.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    },
    isMeterRouteActive: function (currentRouteName) {
      const meterRouteName = ["meter-dashboard", "meter-overview", "meter-tariff"]
      return meterRouteName.includes(currentRouteName)
    },
    onMeterNavOpen: function () {
      this.isMeterNavOpen = !this.isMeterNavOpen
    },
    getName: function () {
      let name = this.GET_USER.first_name + ' ' + this.GET_USER.last_name
      let ellipsis = '...'
      if (name.length > 25) {
        return name.slice(0, 25 - ellipsis.length) + ellipsis;
      }
      return name;
    },
    hasPermission: function (name) {
      if (!this.GET_USER) {
        return false
      }
      const feature_set = this.GET_USER.merchant.feature_set
      if (!feature_set) {
        return false
      }
      const has_feature = feature_set.filter(item => item.name === name)
      return has_feature.length !== 0;

    },
    hasFeatureSet: function () {
      if (!this.GET_USER) {
        return false
      }
      const feature_set = this.GET_USER.merchant.feature_set
      if (!feature_set) {
        return false
      }
      return feature_set.length > 0
    },
    getProfile: function () {
      this.loadingInformation = true
      this.$store.dispatch('user/GET_ACCOUNT', {'id': this.GET_USER.id}).then(() => {
        this.loadingInformation = false
      }).catch(() => {
        this.loadingInformation = false
      })
    },

  },
  computed: {
    ...mapGetters({
      SIDE_BAR: 'general/getSideBar',
      GET_USER: 'user/GET_USER'
    }),

  }
}
</script>
