<template>
  <button type="submit" style=""
          class="btn __css height text-white primary__color" :class="{'btn-block': size === 'lg'}"
          :disabled="loading">{{ text }}<i
      class="el-icon-loading ml-2" v-if="loading"></i></button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    size: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.height {
  height: 45px;
}

.__css {
  border-radius: 5px !important;
  font-weight: 500;
  margin-top: 17px
}
</style>