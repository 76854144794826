<template>
  <div
      class="bg-white rounded p-4 flex gap-4 border hover:shadow-xs hover:border-4 hover:border hover:border-gray-500 cursor-pointer"
      v-on:click="onClickTab">
    <div class="rounded-xl bg-purple-200 h-24 w-24 flex justify-center align-center py-[20px] border">
      <img :src="iconList[item.icon]" :alt="item.name" class="h-12 w-12">
    </div>
    <div class="px-2">
      <div class="pb-6">
        <h2 class="text-4xl font-bold">{{ item.total }}</h2>
      </div>
      <div>
        <p class="text-sm">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Meter from "../../../assets/meter/meter.svg";
import Money from "../../../assets/meter/money.svg";
import Package from "../../../assets/meter/package.svg";
import User from "../../../assets/meter/user.svg";

export default {
  name: "OverviewCard",
  props: {
    item: {
      type: Object
    }
  },
  data: function () {
    return {
      iconList: {
        meter: Meter,
        user: User,
        money: Money,
        package: Package
      }
    }
  },
  methods: {
    onClickTab: function () {
      if (!this.item.path) {
        return
      }
      this.$router.push({
        name: this.item.path
      })
    }
  }
}
</script>

<style scoped>

</style>