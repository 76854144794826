<template>
  <div class="w-full mb-4">

    <div class="flex mb-[10px]" v-if="actionList">
      <div>
        <el-dropdown @command="handleActionCommand">
          <el-button type="primary">
            Action <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="action in actionList" :key="action.title" :command="action.command">
              {{ action.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div
        class="overflow-x-auto rounded-xl border shadow-sm bg-white">
      <table class="table border-collapse inline-table w-full min-w-max overflow-y-hidden overflow-x-auto">
        <thead>
        <tr>
          <th :class="`!font-semibold text-[0.85rem] !border border-slate-300 ${column.customClass}`"
              v-for="(column, i) in columns"
              :key="`${i}-${column.name}`">
            <span v-if="column.type === 'checkbox'">
              <input type="checkbox" v-on:change="onCheckAll">
            </span>
            <span v-else>
              {{ column.label }}
            </span>
          </th>
        </tr>
        </thead>
        <tbody v-if="results.length > 0">
        <tr class="bg-white text-[15px] !font-light" v-for="(item,i) in results" :key="i">
          <td class="text-left text-[0.85rem] !font-light" v-for="(col, index) in columns" :key="index">
           <span v-if="col.type === 'field'">
             {{ item[col.name] }}
           </span>
            <span v-else-if="col.type === 'checkbox'">
             <input type="checkbox" :checked="item[col.name]" v-on:change="onSingleCheck(item)"
                    v-model="item[col.name]">
            </span>
            <span v-else-if="col.type === 'custom'">
             {{ col.custom(item) }}
           </span>
            <div v-else-if="col.type === 'bool'">
              <span class="bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black"
                    v-if="item[col.name]"> {{ col.statusName.active }} </span>
              <span class="bg-[#FE9431] px-[15px] py-[5px] rounded text-[12px] font-semibold text-black"
                    v-else> {{ col.statusName.inactive }} </span>
            </div>
            <div v-else-if="col.type === 'customOption'">
              <div v-for="(op,opIndex) in col.options" :key="`custom-${opIndex}`">
         <span :class="op.customClass" v-if="op.value === item[col.name]">
                        {{ op.label }}
                      </span>
              </div>

            </div>
            <div v-else-if="col.type === 'action'" class="grid grid-cols-2 gap-4">
              <div v-for="(method,mi) in filterMethod(col.methodList,item)" :key="mi">
                  <span v-on:click="method.func(item)"
                        :class="`cursor-pointer ${method.css} flex gap-2 align-center justify-between`">
                    {{ method.title }}
                  <img :src="supportedIcons[method.icon]" alt="" class="h-4 w-4">
              </span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>

        <tbody v-if="results.length <= 0">
        <tr>
          <td :colspan="columns.length"><p class="text-center"> No data available.</p></td>
        </tr>

        </tbody>
      </table>

    </div>

    <div class="flex justify-between mb-4" v-if="!hideFooter">
               <span class="flex justify-between mt-4">
                                  <span class="text-base"></span>
                                  <span>
                    <el-select v-model="limit" placeholder="Select" size="small" style="width: 69px;"
                               v-on:change="onResultChange">
                      <el-option
                          v-for="item in [10,20,30,40,50]"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                    </el-select>
                                  </span>
                              </span>
      <el-pagination class="float-right pag mt-4"
                     background
                     @current-change="onChangePageNumber"
                     layout="prev, pager, next"
                     :total="total_pages">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import DeleteIcon from "../../assets/meter/delete.svg";
import EditIcon from "../../assets/meter/edit.svg";
import SendIcon from "../../assets/meter/send.svg";
import SettingIcon from "../../assets/meter/setting.svg";
import CancelIcon from "../../assets/meter/cancel.svg";

export default {
  name: "TableComponent",
  props: {
    total_pages: {
      type: Number,
    },
    results: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    actionList: []
  },
  data: function () {
    return {
      limit: 10,
      supportedIcons: {
        delete: DeleteIcon,
        edit: EditIcon,
        send: SendIcon,
        setting: SettingIcon,
        cancel: CancelIcon
      }
    }
  },
  methods: {
    onCheckAll: function () {
      this.$emit('onCheckAll')
    },
    onSingleCheck: function (item) {
      this.$emit('onSingleCheck', item)
    },
    onResultChange: function () {
      this.$emit('onResultChange', this.limit)
    },
    onChangePageNumber: function (value) {
      this.$emit('onChangePageNumber', value)
    },
    filterMethod: function (methodList, item) {
      return methodList.filter(method => {
        if (method.enabled(item)) {
          return method
        }
      })
    },
    handleActionCommand: function (command) {
      this.$emit('handleActionCommand', command)
    }
  }
}
</script>

<style scoped>
.el-dropdown {
  vertical-align: top;
}

.el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>