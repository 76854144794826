<template>
  <div class="container">
    <div class="row no-gutters mb-7">
      <div class="col-6">
        <PageTitle header="Device setup"/>
      </div>
      <div class="col-6">
        <div class="form-group filter-holder">
          <el-button type="primary" icon="el-icon-arrow-left" v-on:click="goBack" size="mini">BACK</el-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card card-custom">
          <div class="card-body">
            <div class="col-md-12">
              <div class="mb-3">
                <h2 class="text-left">Device information</h2>
                <p class="text-left" style="font-size: 13px;margin-top: 4px">Kindly supply the correct device
                  configuration.</p>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Serial number:</label>
                <el-input placeholder="Serial number" v-model="payload.serial_number"
                          v-on:change="OnResolveDevice"
                          class="w-100"></el-input>
                <span v-if="resolving_device" style="position: absolute;top: 36px;right: 8px;"><i
                    class="fa fa-spinner fa-spin"></i></span><span v-if="verified"
                                                                   style="position: absolute;top: 36px;right: 8px;"><i
                  class="fa fa-check text-success"></i></span>
              </div>
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Device display name:</label>
                <el-input placeholder="Device display name" v-model="payload.display_name"
                          class="w-100"></el-input>
              </div>
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Default price:</label>
                <el-input placeholder="Serial number" type="number" v-model="payload.transport_fare_price"
                          class="w-100"></el-input>
                <span class="text-danger float-left pt-1 font-size-10">Price to be charged from the card</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-body">
            <div class="col-md-12">
              <div class="mb-3">
                <h2 class="text-left">Device operating route</h2>
                <p class="text-left" style="font-size: 13px;margin-top: 4px">This settings indicate the operating route
                  of the device.</p>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Start location:</label>
                <el-input placeholder="Start location" v-model="payload.start_location"
                          class="w-100"></el-input>
              </div>
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Destination location:</label>
                <el-input placeholder="Destination location" v-model="payload.destination_location"
                          class="w-100"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-body">
            <div class="col-md-12">
              <div class="mb-3">
                <h2 class="text-left">Device security setting</h2>
              </div>

            </div>
            <div class="col-md-12">
              <div class="form-group mt-2 mb-2" style="position: relative">
                <label class="label mb-1" style="margin-bottom: 2px !important;">Passcode:</label>
                <el-input placeholder="Start location" v-model="code"
                          class="w-100"></el-input>
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" native-type="button" :disabled="loading"
                   v-on:click="submit"
                   style="margin-bottom: 100px;float: left">
          Create <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import {mapGetters} from "vuex"
import PageTitle from "../../../layout/PageTitle";

export default {
  name: "CreateDevice",
  components: {PageTitle},
  data: function () {
    return {
      resolving_device: false,
      device_resolve_error: false,
      loadingInformation: false,
      verified: false,
      loading: false,
      code: '',
      payload: {
        serial_number: '',
        display_name: '',
        start_location: '',
        destination_location: '',
        transport_fare_price: 0,
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push('/devices')
    },
    OnResolveDevice: function () {
      if (this.payload.serial_number !== '') {
        this.resolving_device = true
        this.verified = false
        this.$store.dispatch('device/verifyDevice', {
          "serial_number": this.payload.serial_number
        }).then(() => {
          this.resolving_device = false
          this.verified = true

        }).catch(() => {
          this.resolving_device = false
          this.$toast.warn({
            'title': 'warning',
            'message': 'Device with this serial number does not exist on our system'
          })
        })
      }
    },
    submit: function () {
      this.loading = true
      if (this.payload.serial_number === '') {
        this.loading = false
        return this.$toast.warn({
          title: 'warning',
          message: 'Kindly supply valid serial number'
        })
      }
      this.$store.dispatch('device/createDevice', this.payload).then(() => {
        this.loading = false
        this.$toast.success({
          'title': 'success',
          'message': 'Device successfully added to your account'
        })
        let self = this
        setTimeout(function () {
          self.$router.push('/devices')
        }, 3000)
      }).catch(err => {
        this.loading = false
        if (err.response.status === 400) {
          this.$toast.warn({
            'title': 'warning',
            'message': err.response.data.message
          })
        } else if (err.response.status === 401) {
          window.Bus.$emit('open-login-session')
        } else {
          this.$toast.warn({
            'title': 'warning',
            'message': 'An error occur while processing your request,We are currently on it ,kindly try again'
          })
        }
      })
    }
  },
  computed: {},
  mounted() {

  }
}
</script>

<style scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0 1rem;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

.table tr {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  font-size: 14px;
}

.table tr td, .table tr th {
  background: none;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

.search-input {
  width: 20%;
  float: left;
  margin-bottom: 25px
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: right;
  }

  .search-input {
    width: 50% !important;
  }
}

.font-size-10 {
  font-size: 10px;
  color: #8862df !important;
}

.icon-sort {
  font-size: 10px;
  cursor: pointer;
}
</style>