<template>
  <div class="mt-6 w-full lg:max-w-[900px]">
    <div class="w-full flex gap-4 justify-between">
      <h4 class="font-medium text-[13px]">METER READING</h4>
    </div>
    <div class="w-full">
      <TableComponent :results="results" :total_pages="total_pages" :columns="columns" @onResultChange="onResultChange"
                      @onChangePageNumber="onChangePageNumber"/>
    </div>
  </div>
</template>

<script>
import TableComponent from "../../../../../components/Table"
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "MeterTableReading",
  components: {TableComponent},
  data: function () {
    return {
      results: [],
      total_pages: 1,
      limit: 10,
      columns: [
        {
          name: 'created_at',
          label: 'DATE / TIME',
          type: 'custom',
          custom: (row) => {
            if (!row.created_at) return ""
            return `${this.dateFormat(row.created_at)}`
          }
        },
        {
          name: 'power',
          label: 'LOAD',
          type: 'field',
        },
        {
          name: 'energy_consumed',
          label: 'ENERGY CONSUMED',
          type: 'field',
        },
        {
          name: 'units_remaining',
          label: 'UNIT',
          type: 'field',
        },
        {
          name: 'tamper_detection',
          label: 'Tamper Detection',
          type: 'customOption',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          options: [
            {
              label: 'Detected',
              customClass: 'bg-red-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: true
            },
            {
              label: 'Not Detected',
              customClass: 'bg-green-500 px-[15px] py-[5px] rounded text-[12px] font-semibold text-black',
              value: false
            },
          ]
        },
        {
          name: 'tariff',
          label: 'TARIFF',
          type: 'field',
        },
      ]
    }
  },
  methods: {

    onChangePageNumber: function (value) {
      let page = `?page=${value}&page_size=${this.limit}`
      this.onMakeRequest(page)
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_reading/getMeterReadings', {
        data: page,
        merchant: merchant,
        meter_code: this.$route.params.code,
      }).then((resp) => {
        this.results = resp.data.results
        this.total_pages = resp.data.total
      })
    },
    onResultChange: function (limit) {
      this.limit = limit
      let page = `?page_size=${this.limit}`
      this.onMakeRequest(page)
    },
    dateFormat: function (dt_created) {

      if (!dt_created) {
        return ''
      }
      return moment(dt_created).format('YYYY-MM-DD hh:mm a')
    },
    getReading: function () {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_reading/getMeterReadings', {
        meter_code: this.$route.params.code,
        merchant: merchant,
        data: "?limit=10"
      }).then(resp => {
        this.results = resp.data.results
        this.total_pages = resp.data.total
      })
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
    }),
  },
  mounted() {
    this.getReading()
  }
}
</script>

<style scoped>

</style>