<template>
  <tr>
    <td style="width: 24%;" class="text-left">
      {{ item.id }}
    </td>
    <td>
      <span>₦{{ item.amount }}</span>
    </td>
    <td>
      <span class="badge badge-info badge-size" v-if="item.status==='pending'"> PENDING </span>
      <span class="badge badge-success badge-size" v-else-if="item.status==='paid'"> PAID </span>
      <span class="badge badge-warning badge-size" v-else-if="item.status==='unpaid'"> UNPAID </span>
      <span class="badge badge-danger badge-size" v-else-if="item.status==='failed'"> FAILED </span>
      <span class="badge badge-danger badge-size" v-else> TERMINATED </span>

    </td>
    <td>
      <span><span v-if="item.created_at">{{ item.created_at }}</span><span v-else>---</span></span>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "tablelist",
  props: {
    item: {
      type: Object,
      required: true,
    },
    counter: {
      required: true
    }
  },
  data: function () {
    return {
      withdraw: {}
    }
  },
  methods: {
    dateFormating() {
      return moment(this.item.created_at).format('YYYY-MM-DD')
    },
    toggle_checkbox: function (event) {
      this.withdraw.status = event.target.checked
    },
    onEdit() {
      this.$router.push({name: 'device-setting', params: {'id': this.item.id}})
    },
  },
  mounted() {
    this.withdraw = this.item
  }
}
</script>

<style scoped>
.badge-size {
  padding: 5px 13px 4px;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

.table tr {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  font-size: 14px;
}

.table tr td, .table tr th {
  background: none;
  font-size: 14px;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: left;
  }

}

.toggle {
  margin-bottom: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>