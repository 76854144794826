<template>
  <div style="background-color:#f9f9fb !important;">
    <div class="v-application v-application--is-ltr theme--light">
      <div class="v-application--wrap">
        <div class="v-main login">
          <div class="v-main__wrap">
            <div class="container-fluid fill-height">
              <div class="row pl-0 fill-height">
                <div class="login-content-wrapper d-none d-md-flex col-md-6 col-12">
                  <div class="login-content">
                    <img src="../../assets/images/illustration.png" class="home-illustration">
                  </div>
                </div>
                <div class="form-wrapper col-md-6 col-12 auth-inner side_form">
                  <div class="login-wrapper">
                    <img src="./../../assets/images/cardfare_bg_2.png" data-selenium="logo"
                         class="logo" style="height: 72px"
                         alt="logo">
                    <h1 class="text-left">Sign Up</h1>
                    <p class="preamble text-left mb-4">
                      Let's get you started with your <strong>Cardfare</strong> merchant account
                    </p>
                    <div class="form-group">
                      <form class="signup_validate" v-on:submit.prevent="submit">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">First name:</label>
                              <el-input placeholder="first name" class="w-100" v-model="form.first_name"></el-input>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">Last name:</label>
                              <el-input placeholder="last name" class="w-100" v-model="form.last_name"></el-input>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">Email:</label>
                              <el-input placeholder="email" class="w-100" v-model="form.email"></el-input>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">Mobile:</label>
                              <el-input placeholder="mobile number" type="number" class="w-100" v-model="form.mobile"></el-input>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">Password: </label>
                              <el-input placeholder="Please input password" v-model="form.password"
                                        show-password></el-input>

                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="label">Confirm Password:</label>
                              <el-input placeholder="Please input confirm password" v-model="form.confirm_password"
                                        show-password></el-input>

                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="text-center mt-2">
                              <button-component :text="text" :size="'lg'" :loading="loading"/>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div>
                    <div class="form-group" style="margin-top: 27px !important;">
                      <router-link :to="{name:'login'}" class="mb-2"
                                   style=" margin-bottom: 12px !important;   margin-top: 20px !important;text-align: center !important;font-size: .875rem">

                        Already have an account?
                      </router-link>
                    </div>
                    <div class="site-info-wrapper site-footer"><span>Copyright ©</span>
                      {{ year }}
                      <a href="https://www.cardfare.ng"><span id="__name">Cardfare</span></a></div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "../reusable/ButtonComponent";

export default {
  name: "register",
  components: {ButtonComponent},
  data: function () {
    return {
      section: 0,
      card_number: '',
      card: '',
      verified: false,
      verifiedClass: 'w-100',
      year: new Date().getFullYear(),
      form: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        password: '',
        type:0,
        confirm_password: '',

      },
      loading: false,
      text: 'Sign Up'
    }
  },
  methods: {
    switchTab(tab) {
      this.section = parseInt(tab)
    },
    submit: function () {
      this.loading = true
      for (const [key, value] of Object.entries(this.form)) {
        if (value === '' || value === null) {
          this.loading = false
          return this.$toast.warn({
            title: 'warning',
            message: `${key.replace('_', ' ')} is required`
          })
        }
      }
      if (this.form.confirm_password !== this.form.password) {
        this.loading = false
        return this.$toast.warn({
          title: 'warning',
          message: `Password does not match`
        })
      }
      let payload = {}
      for (const [key, value] of Object.entries(this.form)) {
        payload[key] = value
      }
      delete payload['confirm_password']
      this.$store.dispatch('auth/register', payload)
          .then(() => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: `Account created successfully,an email verification has been sent to your mail`
            })
            let self = this
            setTimeout(function () {
              self.$router.push({'name': 'login'})
            }, 1000)

          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    }
  },
  mounted() {
    const {type} = this.$route.query
    if (type) {
      if (type === 'vendor') {
        this.section = 2
      } else if (type === 'customer') {
        this.section = 1
      } else {
        this.$router.push({name: 'register'})
      }
    }
    this.$store.dispatch('general/getCountry')
  }
}
</script>

<style scoped>
.font-weight-800 {
  font-weight: 800;
}

.no-border-radius {
  border-radius: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-7 {
  margin-top: 7px !important;
}

.theme--light.v-application {
  background: #fff;
  color: rgba(0, 0, 0, .87);
}

.v-application {
  display: flex;
}

.v-application--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.login-page .v-content, .login-page .v-main {
  padding: 0 !important;
}

main.login, .login {
  background-color: #ffffff !important;
}

.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4, 0, .2, 1);
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}

@media screen and (max-height: 640px) {
  .login-page .v-content .container, .login-page .v-main .container {
    margin-bottom: 55px;
  }

  .login-wrapper {
    margin: 0 auto;
  }

  .auth-inner:before {
    display: none
  }

  .logo {
    height: 59px !important;
    margin: 0 auto 81px 0 !important;
  }

  .site-info-wrapper {
    color: #777;
    font-size: 12px;
    /*align-self: center !important;*/
    /*margin-top: 0px !important;*/
    /*position: relative;*/
    /*top: 142px !important;*/
    text-align: center !important;
  }

  .form-wrapper h1 {
    color: #282947;
    text-transform: none;
    margin-bottom: 12px !important;
    font-size: 23px !important;
  }

  .preamble {
    font-size: 12px !important;
  }
}


.login-page .v-content .container, .login-page .v-main .container {
  margin: 0 !important;
  padding: 0 !important;
}

.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100% + 24px);
}

.login-page .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-application .pl-0 {
  padding-left: 0 !important;
}

.login-wrapper {
  display: flex;
  padding: 0 0px;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  /*height: 100vh !important;*/
}

.logo {
  margin: 0 auto 60px 0;
  display: block;
  padding-top: 30px;
}

.form-wrapper h1 {
  color: #282947;
  text-transform: none;
  margin-bottom: 25px;
  font-size: 27px;
  font-weight: 600;
  line-height: 1.4;

}

#app {
  margin-top: 0px !important;
}

.preamble {
  font-size: 15px;
  color: #666;
  margin-bottom: 0px;
  font-weight: 600;
}


.auth-inner:before {
  width: 244px;
  height: 243px;
  content: ' ';
  position: absolute;
  top: 457px;
  left: -46px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC)
}

.login-content-wrapper {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #ebe9fc;
  height: 759px !important;
}

.login-content-wrapper .login-content {
  position: relative;
  padding: 15px;
  width: 100%;
}

.site-info-wrapper {
  color: #777;
  font-size: 12px;
  text-align: right;
  margin-top: auto;
}

#__name {
  color: #8862df;
  font-weight: 600;
}

.home-illustration {
  max-width: 655px;
  min-width: 300px;
}

.side_form {
  /*min-height: 100vh;*/
  /*height: 100vh;*/
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
}

</style>