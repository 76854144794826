<template>
  <div class="sidebar border-r"
       :class="{'show': SIDE_BAR === true,'hide':SIDE_BAR === false}"
       style="padding: 20px !important;overflow-y: scroll;">
    <div>
      <div class="sc-kLgnNl jgpRUf flex items-center justify-between p-2 cursor-pointer">
        <div>
          <div class="text-caption font-medium text-gray-400 business to-Uppercase text-left">{{ getName() }}
          </div>
          <div class="text-sm text-gray-300 text-left">{{ GET_USER.username }}</div>
        </div>
      </div>
    </div>
    <div class="menu mt-[20px]">
      <ul>
        <li>
          <router-link :to="{name:'get-started'}"
                       :class="`${$route.name === 'get-started' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`">
            <span class="nav-text">Get Started</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'dashboard'}"
                       :class="`${$route.name === 'dashboard' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`">
            <span class="nav-text">Overview</span>
          </router-link>
        </li>
        <li>
          <router-link
              :class="`${$route.name === 'devices' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`"
              :to="{name: 'devices'}">
            <span class="nav-text">Transportation</span>
          </router-link>
        </li>

        <li class="hidden">
          <router-link
              :class="`${$route.name === 'withdrawal' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`"
              :to="{name: 'withdrawal'}">
            <span class="nav-text">Withdraw</span>
          </router-link>
        </li>
        <li>
          <router-link
              :class="`${$route.name === 'transaction' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`"
              :to="{name: 'transaction'}">
            <span class="nav-text">Transactions</span>
          </router-link>
        </li>
        <li>
          <router-link
              :class="`${$route.name === 'settings' ? 'border-l-[2px] border-l-[#8862df]' : ''} link-text hover:border-l-[2px] hover:border-l-[#8862df]`"
              :to="{name: 'settings'}">
            <span class="nav-text">Setting</span>
          </router-link>
        </li>
        <li v-on:click="signOut">
          <a class="link-text" href="javascript:void(0)">
            <span class="nav-text">Sign out</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "CustomerSidebar",
  data: function () {
    return {
      currentDate: '',
      name: '',
      isMeterNavOpen: false
    }
  },
  mounted() {
    this.currentDate = new Date().getFullYear()
    this.getProfile()

  },
  methods: {
    signOut: function () {
      this.$store.dispatch('user/LOGOUT').then(() => {
        const routerName = this.$router.resolve({name: 'login'})
        setTimeout(() => {
          window.open(routerName.href, '_parent')
        }, 200)
      })
    },
    isMeterRouteActive: function (currentRouteName) {
      const meterRouteName = ["meter-dashboard", "meter-overview", "meter-tariff"]
      return meterRouteName.includes(currentRouteName)
    },
    onMeterNavOpen: function () {
      this.isMeterNavOpen = !this.isMeterNavOpen
    },
    getName: function () {
      let name = this.GET_USER.first_name + ' ' + this.GET_USER.last_name
      let ellipsis = '...'
      if (name.length > 25) {
        return name.slice(0, 25 - ellipsis.length) + ellipsis;
      }
      return name;
    },
    hasPermission: function (name) {
      if (!this.GET_USER) {
        return false
      }
      const feature_set = this.GET_USER.merchant.feature_set
      if (!feature_set) {
        return false
      }
      const has_feature = feature_set.filter(item => item.name === name)
      return has_feature.length !== 0;

    },
    hasFeatureSet: function () {
      if (!this.GET_USER) {
        return false
      }
      const feature_set = this.GET_USER.merchant.feature_set
      if (!feature_set) {
        return false
      }
      return feature_set.length > 0
    },
    getProfile: function () {
      this.loadingInformation = true
      this.$store.dispatch('user/GET_ACCOUNT', {'id': this.GET_USER.id}).then(() => {
        this.loadingInformation = false
      }).catch(() => {
        this.loadingInformation = false
      })
    },
  },
  computed: {
    ...mapGetters({
      SIDE_BAR: 'general/getSideBar',
      GET_USER: 'user/GET_USER'
    }),

  }
}
</script>

<style scoped>
.sidebar {
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: -3px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  width: 240px;
  z-index: 1055;
}


@media only screen and (max-width: 640px) {
  .sidebar {
    background: #ffffff;
  }

}

@media only screen and (max-width: 767px) {
  .menu {
    margin-top: 61px !important;
    position: relative;
    top: -68px;
  }


}

.logo_ {
  width: 121px !important;
  position: relative !important;
  top: 16px !important;
  height: auto !important;
}

.text-sm2 {
  font-size: 12px;
  line-height: 18px;
}

.logo-bottom {
  margin-top: 11.5rem;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-250 {
  --tw-text-opacity: 1;
  color: rgba(207, 207, 207, var(--tw-text-opacity));
}

.tracking-widest {
  letter-spacing: .1em;
}

.uppercase {
  text-transform: uppercase;
}

.text-desc {
  text-align: left !important;
  /* float: left; */
  position: relative;
  left: -43px;
  margin-bottom: 10px;
}

.margin-top-29 {
  margin-top: 29px !important;
}

.link-text {
  font-size: 14px;
}

.jgpRUf {
  width: 200px;
  height: 56px;
  border: 1.5px solid rgb(254, 254, 254);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 3px rgb(55 55 89 / 8%);
}

.text-caption {
  font-size: 13px !important;
}

.to-Uppercase {
  text-transform: uppercase;
}
</style>