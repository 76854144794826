<template>
  <div class="h-full py-6 px-[20px]">
    <TabComponents :tabs="tabs" @change="switchTab"
                   :css="`flex flex-row flex-wrap overflow-x-auto overflow-y-hidden`"/>

    <div class="pt-[10px]" v-if="tab === 'transaction'">
      <merchant-transaction/>
    </div>
    <div class="pt-4.5" v-if="tab === 'meter-transaction'">
      <meter-transaction/>
    </div>
  </div>
</template>

<script>
import TabComponents from "../../../../components/Tabs";
import meterTransaction from "../../../merchant/MeterPortal/transactions/meter/dashboard"
import merchantTransaction from "../../../merchant/MeterPortal/transactions/merchant/dashboard"
import {mapGetters} from "vuex";

export default {
  name: "dashboard",
  components: {TabComponents, meterTransaction, merchantTransaction},
  data: function () {
    return {
      payload: {
        filter: "",
        search: ""
      },
      loading: false,
      tabs: [
        {
          name: 'Wallet Transaction',
          active: true,
          slug: 'transaction'
        },
        {
          name: 'Meters Transaction',
          active: false,
          slug: 'meter-transaction'
        },
      ],
      tab: "transaction"

    }
  },
  methods: {
    switchTab: function (tab) {
      const {slug} = tab
      this.tabs.forEach((item) => {
        item.active = item.slug === slug;
      })
      this.tab = slug
    },
    computeParam: function () {
      let page = `page=${this.currentPage}&page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&status=${this.payload.filter}`
      }
      return page
    },
    changePageNumber: function (value) {
      this.currentPage = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getTariffs', {data: page, merchant: merchant}).then(() => {
        this.loading = false
      })
    },
    changeResultNumber: function (value) {
      this.limit = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onSearch: function () {
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    getTariffs: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter/getTariffs', {data: `page_size=${this.limit}`, merchant: merchant}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_TARIFFS: 'meter/GET_TARIFFS'
    }),

  },
}
</script>

<style scoped>

</style>