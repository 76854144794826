<template>
  <div
      class="w-full shadow-[1px] border-[2px] border-none hover:rounded-lg hover:border-[2px] hover:border-gray-200 rounded min-h-[320px] bg-white">
    <div class="w-full h-[200px] mx-auto flex justify-center py-[20px]">
      <img :src="service.image" alt="Image display" class="h-[150px] w-[150px]"/>
    </div>
    <div class="px-3 h-[120px]">
      <h2 class="font-semibold text-center">{{ service.name }}</h2>
      <div class="pt-[30px]">
        <el-button v-if="canAccess()" v-on:click="accessDashboard()"
                   class="w-full bg-mainPurple rounded text-white hover:bg-mainPurple !border-mainPurple !hover:border-mainPurple">
          Access
        </el-button>
        <el-button v-else
                   class="w-full bg-mainPurple rounded text-white hover:bg-mainPurple !border-mainPurple !hover:border-mainPurple">
          Request Access
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    service: {
      type: Object,
    },
    features: {
      type: Array
    }
  },
  methods: {
    canAccess() {
      const hasFeature = this.features.filter(item => item.feature.name === this.service.slug);
      return hasFeature.length > 0;
    },
    accessDashboard() {
      const routerName = this.$router.resolve({name: this.service.path})
      setTimeout(window.open(routerName.href, '_parent'), 200)
    }
  },
  mounted() {
    console.log(this.features)
  }
}
</script>

<style scoped>

</style>