<template>
  <div class="w-full py-6">
    <MeterCardReading/>
    <SingleMeterOverview/>
  </div>
</template>

<script>
import MeterCardReading from "./MeterCardReading";
import SingleMeterOverview from "./SingleMeterOverview";

export default {
  name: "Overview",
  components: {MeterCardReading,SingleMeterOverview}
}
</script>

<style scoped>

</style>