<template>
  <div class="min-h-[650px] py-6 px-[25px] bg-mainBG">
    <div class="flex flex-row justify-between">
      <h4 class="font-semibold">Devices</h4>
      <div>
        <el-button type="primary" icon="el-icon-plus" v-on:click="addNewDevice" size="mini" :disabled="loading">ADD
          DEVICE
        </el-button>
      </div>
    </div>
    <div class="w-full">
      <TableComponent :results="DEVICES.results" :total_pages="DEVICES.total_pages" :columns="columns"
                      @onResultChange="changeResultNumber" @onChangePageNumber="changePageNumber"
                      @onDelete="onDelete"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import TableComponent from "../../../components/Table"

export default {
  name: "dashboard",
  components: {TableComponent},
  data: function () {
    return {
      filter: '',
      limit: 30,
      payload: {
        search: ''
      },
      loading: true,
      showMiddleAddBtn: true,
      loadingData: false,
      message: 'You are yet to add a device to your account',
      columns: [
        {
          name: 'serial_number',
          label: 'Serial Number',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            if (!value.setting) {
              return ""
            }
            return value.setting.serial_number
          }
        },
        {
          name: 'display_name',
          label: 'Name',
          type: 'field',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
        },
        {
          name: 'created_at',
          label: 'Date Created',
          type: 'custom',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          custom: (value) => {
            return value.created_at
          },

        },
        {
          name: '',
          label: '',
          type: 'action',
          customClass: 'm-w-[200px] lg:m-w-min-w-full',
          methodList: [
            {
              'icon': 'edit',
              title: 'Edit',
              css: 'bg-gray-200 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onEdit(item)
              },
              enabled: () => {
                return true
              }
            },
            {
              'icon': 'delete',
              title: 'Delete',
              css: 'bg-red-500 w-24 py-[5px] px-2 text-black rounded text-[12px] font-semibold',
              func: (item) => {
                this.onDelete(item)
              },
              enabled: () => {
                return true
              }
            },
          ],
        },
      ],
    }
  },
  methods: {
    deleteItem(payload) {
      let itemIndex = this.DEVICES.results.findIndex(item => item.id = payload.id)
      if (itemIndex > -1) {
        this.DEVICES.results.splice(itemIndex, 1)
      }
    },
    onDelete(item) {
      this.$confirm('This will permanently device and associated data from the system?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch('device/deleteDevice', {'id': item.id}).then(() => {
              loading.close()
              done()
              this.$toast.success({
                title: 'success',
                message: 'Device deleted successfully'
              })
              this.deleteItem({id: item.id})
            }).catch(er => {
              loading.close()
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = 'Ok';
              if (er.response.status !== 401) {
                this.$toast.warn({
                  title: 'warning',
                  message: er.response.data.message
                })
              } else {
                window.Bus.$emit('open-login-session')
              }
            })
          } else {
            done();
          }
        }
      }).then(() => {

      }).catch(() => {

      });
    },
    addNewDevice: function () {
      this.$router.push('/device/add-device')
    },
    changePageNumber(value) {
      this.loading = true
      let page = `page=${value}&page_size=${this.limit}&account_id=${this.GET_USER.username}`
      if (this.payload.search) {
        page = page + `&search=${this.payload.search}`
      }
      this.$store.dispatch('card/getCards', {data: page}).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    changeResultNumber() {

    },
    getDeviceList() {
      this.loading = true
      this.$store.dispatch('device/getDevices', {'params': `?limit=${this.limit}`}).then(() => {
        this.loading = false
      }).catch(err => {
        this.loading = false
        if (err.response.status === 401) {
          window.Bus.$emit('open-session-login')
        } else {
          console.log("ma")
        }
      })
    },
    onEdit(item) {
      this.$router.push({name: 'device-setting', params: {'id': item.id}})
    }
  },
  computed: {
    ...mapGetters({
      'DEVICES': 'device/GET_DEVICES'
    })
  },
  mounted() {
    this.getDeviceList()
  },

}
</script>
<style scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0 1rem;
}

.success {
  background: #10d876 !important;
}

.warning {
  background: #d89210 !important;
}

/*.table tr {*/
/*  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);*/
/*  background: #ffffff;*/
/*  font-size: 14px;*/
/*}*/

.table tr td, .table tr th {
  background: none;
}

.table th, .table td {
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.paggy {
  margin-top: 24px !important;
  margin-bottom: 20px;
}

.filter-holder {
  margin-top: 25px;
  float: right;
}

.search-input {
  width: 20%;
  float: left;
  margin-bottom: 25px
}

@media only screen and (max-width: 640px) {
  .filter-holder {
    margin-top: 25px;
    float: right;
  }

  .search-input {
    width: 50% !important;
  }
}

.icon-sort {
  font-size: 10px;
  cursor: pointer;
}
</style>